<mat-card class="card-container" fxLayout="column" fxLayoutAlign="start start">
  <mat-card-header class="card-header">
    <mat-card-title>{{data.title}}</mat-card-title>
  </mat-card-header>

  <div class="card-users" *ngIf="category === cardCategory.GROUP_POLICY_USERS">
    <mat-card-content fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <p>{{data.group}}</p>
        <p *ngIf="data.groupTooltipText">,&nbsp;</p>
        <p class="card-tooltip"
        [matTooltip]="data.groupTooltip"
        matTooltipClass="card-groupTooltip"
        #tooltip="matTooltip"
        (mouseenter)="tooltipService.onTooltipMouseEnter($event, tooltip, data)" (mouseleave)="tooltipService.onTooltipMouseLeave($event, tooltip, data)" (click)="tooltipService.onTooltipClick(tooltip, data)"
        >{{data.groupTooltipText}}</p>
      </div>
      <p class="user-status">{{data.status}}</p>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="data?.editLink" mat-stroked-button type="button" (click)="onNavigate(data.editLink)">
        {{data.editText}}
      </button>
    </mat-card-actions>
  </div>

  <div class="card-roles" *ngIf="category === cardCategory.POLICIES">
    <mat-card-content fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <p><mat-label>{{ data.userText + ":&nbsp;" + data.userCount }}</mat-label></p>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="data?.viewLink" mat-stroked-button (click)="onNavigate(data.viewLink)" >{{data?.viewText}}</button>
      <button *ngIf="data?.editLink" mat-flat-button class="button-ml" (click)="onNavigate(data.editLink)">{{data?.editText}}</button>
    </mat-card-actions>
  </div>
</mat-card>
