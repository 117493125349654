import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { PageNotFoundComponent } from '@app/shared/components/page-not-found/page-not-found.component';
import { FourOhFourMode } from './core/consts/app.const';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'company',
    pathMatch: 'full'
  },

  {
    path: 'company',
    loadChildren: () => import('@app/modules/company/company.module').then(m => m.CompanyModule),

    // canActivate: [AuthGuard, PermissionsGuard] // Why does PermissionsGuard prevent this route to fail; but not '/company'?
    canActivate: [AuthGuard]
  },
  {
    path: 'demo-page',
    loadChildren: () => import('@app/modules/demo/demo.module').then(m => m.DemoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'ping',
    loadChildren: () => import('@app/modules/ping/ping.module').then(m => m.PingModule)
  },
  {
    // Auth lib set default URL
    path: 'no-permission-route',
    component: PageNotFoundComponent,
    data: {
      mode: FourOhFourMode.NO_PERMISSION
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
