import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetCustomProperty, CreateEditCustomPropertyDialogParams } from '@app/core/models/asset-property.model';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { selectAssetCustomProperties, selectAssetCustomPropertyCreated, selectCreatePropertyValueProgress, selectDeletePropertyValueProgress, selectIsSavePropertyAttributeLoading, selectUpdatePropertyValueProgress } from '@app/shared/data-stores/asset-properties/asset-properties-data-store.selects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@zonar-ui/i18n';
import { Subject, Subscription, combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { add, cloneDeep } from 'lodash';
import { VALIDATION_ERROR } from '@app/core/consts/validation.const';
import { createAssetProperty, createPropertyValue, inactivePropertyValue, resetAssetCustomPropertyProgress, updateAssetProperty, updatePropertyValue } from '@app/shared/data-stores/asset-properties/asset-properties-data-store.action';
import { CreateEditPropertyDialogCloseCode } from '@app/core/consts/asset-property.const';
import { MediaObserver } from '@angular/flex-layout';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AssetStatus } from '@app/core/consts/asset.const';
import { isBoolean } from 'lodash';

@Component({
  selector: 'app-create-edit-custom-property-dialog',
  templateUrl: './create-edit-custom-property-dialog.component.html',
  styleUrls: ['./create-edit-custom-property-dialog.component.scss']
})
export class CreateEditCustomPropertyDialogComponent implements OnInit, OnDestroy {
  @ViewChildren('formList') formList: QueryList<ElementRef>;
  translated: any = {};

  customPropertyFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    values: new FormArray([]),
  });
  allProperties: AssetCustomProperty[] = [];
  initialProperty: AssetCustomProperty = {};
  subscription: Subscription;
  showLoadingSpinner: boolean = false;

  onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateEditCustomPropertyDialogParams,
    public dialogRef: MatDialogRef<CreateEditCustomPropertyDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    public parsingService: RegionParserService,
    public i18nService: I18nService,
    protected changeDetectorRef: ChangeDetectorRef,
    public store: Store<any>,
    public media: MediaObserver,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribeToPropertyNameChanges();
    this.subscribeToPropertyValuesChange();

    this.subscription = this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(breakPointState => {
      if (breakPointState.matches) {
        this.dialogRef.updateSize('100vw', '100vh');
      } else {
        this.dialogRef.updateSize('28.5rem', '37rem');
        const sidenav: any = document.querySelector('.cdk-global-overlay-wrapper');
        if (sidenav && sidenav.style) {
          sidenav.style.setProperty('justify-content', 'center');
          sidenav.style.setProperty('align-items', 'center');
        }
      }
    });
  }

  initForm() {
    combineLatest([
      this.store.select(selectAssetCustomProperties),
    ]).pipe(
      takeUntil(this.onDestroy$),
      filter(data => Boolean(data)),
      take(1)
    ).subscribe(([assetProperties]) => {
      if (assetProperties) {
        this.allProperties = assetProperties;
        if (this.data?.propertyId) {
          this.initialProperty = assetProperties.find(prop => prop?.id === this.data?.propertyId);
          this.customPropertyFormGroup.controls.name.setValue(this.initialProperty?.name);
          this.customPropertyFormGroup.controls.name.markAsTouched();
          this.initialProperty?.values?.forEach(val => {
            this.customPropertyFormGroup.controls.values.push(this.getValueFormGroup(val?.id, val?.value));
          });

        } else {
          this.customPropertyFormGroup.controls.name.markAsTouched();
          this.customPropertyFormGroup.controls.values.push(this.getValueFormGroup());
        }
      }
    });
  }

  subscribeToPropertyNameChanges() {
    this.customPropertyFormGroup.controls.name.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe((data) => {
      // validate duplicate of property name
      const dupPropertyName = this.allProperties.find(prop => prop.id !== this.data?.propertyId && prop.name?.toUpperCase() === data?.toUpperCase());
      if (dupPropertyName) {
        this.customPropertyFormGroup.controls.name.setErrors({ duplicate: true });
      }
    });
  }

  subscribeToPropertyValuesChange() {
    this.customPropertyFormGroup.controls.values?.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
      switchMap(() => {
        const valueObjs = this.customPropertyFormGroup.controls.values.controls.map((valueFormGroup, index) => {
          return valueFormGroup.valueChanges.pipe(
            map(value => ({ value, index })));
        });
        return merge(...valueObjs);
      })
    ).subscribe((data) => {
      // validate values duplications
      let valueFormGroups = cloneDeep(this.customPropertyFormGroup.controls.values.controls);
      valueFormGroups.splice(data.index, 1); // remove itself from formGroups to later check duplicate name

      const dupValueName = valueFormGroups.find(x => x.get('value').value.trim() === data.value?.value.trim());
      if (dupValueName) {
        const errorFormGroup: FormGroup = this.customPropertyFormGroup.controls.values.controls[data.index] as FormGroup;
        errorFormGroup.controls.value.setErrors({ duplicate: true });
        errorFormGroup.controls.value.markAsTouched();
      }
    });
  }

  /* values form array */
  getValuesFormControls(): FormGroup[] {
    return this.customPropertyFormGroup.controls.values?.controls as FormGroup[];
  }

  getValueFormGroup(id?: string, value?: string): FormGroup {
    const newFormGroup = new FormGroup({
      'id': new FormControl(id ? id : null),
      'value': new FormControl(value ? value : '', Validators.required),
    });
    newFormGroup.controls.value.markAsTouched();
    return newFormGroup;
  }

  hasDataChanged() {
    if (this.data?.propertyId) {
      return this.hasFormNameChanged() || this.hasFormValuesChanged();
    } else {
      return true;
    }
  }

  hasFormNameChanged() {
    return this.customPropertyFormGroup?.controls?.name?.value !== (this.initialProperty?.name || '');
  }

  hasFormValuesChanged() {
    return this.customPropertyFormGroup?.controls?.values?.length !== this.initialProperty?.values?.length
      || !(this.initialProperty.values.map(initVal => `${initVal.id}-${initVal.value}`).sort().toString()
        === this.customPropertyFormGroup.controls.values.controls.map((ctrl: FormGroup) => `${ctrl.get('id').value}-${ctrl.get('value').value}`).sort().toString());
  }

  /* HTML Binding Functions */
  isAddValueButtonVisible(): boolean {
    return this.customPropertyFormGroup?.controls?.values?.valid;
  }

  isSaveButtonDisable(): boolean {
    return !(this.customPropertyFormGroup?.valid && this.hasDataChanged());
  }

  isDeleteValueIconVisible(): boolean {
    return this.getValuesFormControls().length > 1;
  }

  onDeleteValueClick(index) {
    if (index > -1) {
      const removeValue = this.customPropertyFormGroup.controls.values.controls[index].get('value').value;
      this.customPropertyFormGroup.controls.values.removeAt(index);
      // re-validate all values to remove duplicate errors
      const dupErroredFormGroup = this.customPropertyFormGroup.controls.values.controls.find((currentCtrl) => currentCtrl.get('value').value === removeValue) as FormGroup;
      if (dupErroredFormGroup) {
        if (dupErroredFormGroup.controls.value.hasError(this.getValidationError().duplicate)) {
          delete dupErroredFormGroup.controls.value.errors[this.getValidationError().duplicate];
          dupErroredFormGroup.controls.value.updateValueAndValidity();
        }
      }
    }
  }

  onAddValueClick() {
    this.customPropertyFormGroup.controls.values.push(this.getValueFormGroup());
    this.changeDetectorRef.detectChanges(); // to avoid NG0100 error when adding new form on run time.
    this.formList?.last?.nativeElement?.focus();
  }

  onCancelDialogClick() {
    this.dialogRef.close({ dialogCloseCode: CreateEditPropertyDialogCloseCode.CANCEL });
  }

  onConfirmDialogClick() {
    this.showLoadingSpinner = true;
    // filter forms into added(value with id null) and edited ones
    const { editValues, addValues } = this.customPropertyFormGroup.controls.values.controls.reduce((r, o) => {
      r[o.get('id').value ? 'editValues' : 'addValues'].push({ id: o.get('id').value, name: o.get('value').value });
      return r;
    }, { editValues: [], addValues: [] });

    if (this.data?.propertyId) {
      const updateValues = [];
      const deleteValues = [];
      this.initialProperty.values.forEach(initVal => {
        const existingVal = editValues.find(ev => ev.id === initVal.id);
        if (!existingVal) {
          // delete case: when value not exist from init
          deleteValues.push({ ...initVal });
        } else if (existingVal.name !== initVal.value) {
          // edit case: when value exist and name different from init
          updateValues.push({ ...initVal, name: existingVal.name });
        }
      });

      this.updatePropertyAttribute();
      this.deletePropertyValues(deleteValues, updateValues, addValues);
    } else {
      this.createAssetProperty(addValues);
    }
  }

  updatePropertyAttribute() {
    if (this.isChangeAssetProperty()) {
      this.store.dispatch(updateAssetProperty(
        {
          payload: {
            params: { propertyId: this.data.propertyId, name: this.customPropertyFormGroup.controls.name.value }
          }
        }
      ));
    }
  }

  deletePropertyValues(deleteValues: Array<any>, updateValues: Array<any>, addValues: Array<any>) {
    if (!deleteValues.length) {
      this.updatePropertyValues(updateValues, addValues);
    } else {
      this.store.select(selectDeletePropertyValueProgress)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((deleteProgress) => {
        if (deleteValues.length === deleteProgress) {
          this.updatePropertyValues(updateValues, addValues);
        }
      });

      deleteValues.forEach((val) => {
        this.store.dispatch(inactivePropertyValue({
          payload: { params: {
            propertyId: this.data?.propertyId,
            propertyValueId: val.id,
          }}
        }));
      });
    }
  }

  updatePropertyValues(updateValues: Array<any>, addValues: Array<any>) {
    if (!updateValues.length) {
      this.addPropertyValues(addValues);
    } else {
      this.store.select(selectUpdatePropertyValueProgress)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((updateProgress) => {
        if (updateValues.length === updateProgress) {
          this.addPropertyValues(addValues);
        }
      });

      updateValues.forEach((val) => {
        this.store.dispatch(updatePropertyValue({
          payload: { params: {
            propertyId: this.data?.propertyId,
            propertyValueId: val.id,
            value: val.name,
          }}
        }));
      });
    }
  }

  addPropertyValues(addValues: Array<any>) {
    if (!addValues.length) {
      this.closeDialogAfterSave();
    } else {
      this.store.select(selectCreatePropertyValueProgress)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((createProgress) => {
        if (addValues.length === createProgress) {
          this.closeDialogAfterSave();
        }
      });

      addValues.forEach((val) => {
        this.store.dispatch(createPropertyValue({ payload: { params: { propertyId: this.data?.propertyId, value: val.name } } }));
      });
    }
  }

  closeDialogAfterSave() {
    this.showLoadingSpinner = false;
    this.dialogRef.close({ dialogCloseCode: CreateEditPropertyDialogCloseCode.SUCCESS });
  }

  createAssetProperty(addValues) {
    this.store.dispatch(createAssetProperty({ payload: { params: { companyId: this.data.companyId, name: this.customPropertyFormGroup.controls.name.value } } }));
    this.store.select(selectAssetCustomPropertyCreated)
      .pipe(
        filter(data => Boolean(data)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(assetCustomPropertyCreated => {
        addValues.forEach(val => {
          this.store.dispatch(createPropertyValue({ payload: { params: { propertyId: assetCustomPropertyCreated.id, value: val.name } } }));
        });
        this.closeDialogAfterCreateAssetProperty(addValues);
      });
  }

  closeDialogAfterCreateAssetProperty(values) {
    this.store.select(selectCreatePropertyValueProgress)
      .pipe(
        filter(data => Boolean(data)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(data => {
        if (data === values.length) {
          this.showLoadingSpinner = false;
          this.dialogRef.close({ dialogCloseCode: CreateEditPropertyDialogCloseCode.SUCCESS });
        }
      });
  }

  isChangeAssetProperty() {
    return this.customPropertyFormGroup?.controls?.name?.value !== this.initialProperty?.name;
  }

  onDeletePropertyClick(propertyId) {
    this.store.dispatch(updateAssetProperty({ payload: { params: { propertyId, status: AssetStatus.INACTIVE } } }));
    this.store.select(selectIsSavePropertyAttributeLoading)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(data => isBoolean(data))
      )
      .subscribe(data => {
        if (!data) {
          this.dialogRef.close({ dialogCloseCode: CreateEditPropertyDialogCloseCode.SUCCESS });
        }
      });
  }

  getValidationError() {
    return VALIDATION_ERROR;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.subscription.unsubscribe();
    this.store.dispatch(resetAssetCustomPropertyProgress());
  }

}
