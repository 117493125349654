export const versions = {
  v1: 'v1'
};

export const environment = {
  name: 'development',
  authEnv: 'development',
  region: 'NA',
  production: false,
  devTools: true,
  appUrl: 'https://administration.dev.zonarsystems.net',
  nestedDepthLevelLimit: 3,
  datadog: {
    applicationId: '72927e10-6ce5-4d04-8687-bdd3a7113fc1',
    clientToken: 'puba2abfc79c33d6274ba35fa71ea8bb2e7',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'development'
  },
  googleTagManager: {
    id: 'GTM-5L42RZX',
    auth: 'BU1MqbIvfn1vM6iXtWNSRQ',
    environment: 'env-3'
  },
  coreEntityApiBase: {
    url: `https://api-dev.zonarsystems.net/core/v1`,
    urlCVIConfig: `https://zonar-nonprod-dev.apigee.net/evir/v1`
  },
  userPreferenceApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  },
  auth: {
    clientID: 'u062KPqZOIKkB2DlZcUEClZjiyKSans6',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    applicationId: 'b9203a2f-1a72-42ff-bcba-b7c017a1d49d',
  },
  i18n: {
    supportedLanguages: ['en', 'en-US', 'en-GB', 'de', 'fr-FR', 'es-ES', 'it'],
    defaultLanguage: 'en-US',
  },
  pendo: {
    url: `https://cdn.pendo.io/agent/static/`,
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5'
  },
  footerUrls: {
    supportLink: 'https://zonarsystemshelp.zendesk.com/hc/en-us',
    dataDisclosureLink: 'https://zonarsystems.com/data-disclosure/ground-traffic-control/'
  },
  supportedProducts: [
    {
      name: 'evir',
      productId: '4a04de13-944b-46f7-91dd-a41918de20b6',
      translatedPath: 'supportedProducts.evir',
      default: false,
      configurable: false,
    }
  ],
  gtcUserManagement: {
    selectAccountLink: 'https://gtc4dev-cloud.zonarsystems.net/select-account',
    userManagementLink: 'https://gtc4dev-cloud.zonarsystems.net/legacy?url=%2Fgtc%2F%3Faction%3Dadminusers%26operation%3Dview',
  },
  gtcAssetManagement: {
    assetManagementLink: 'https://gtc4dev-cloud.zonarsystems.net/legacy?url=%2Fadmin%2Fassets%2F',
  },
  gcpMigration: {
    migrationCompleteUrl: 'https://us-west1-self-service-work-na-e783.cloudfunctions.net/completeGroupPolicyMigrationDev',
    migrationCompleteEnv: 'dev',
  },
  features: {
    'app:side-nav': true,
    'app:group-policy': true,
    'company:create': true,
    'company:edit': true,
    'division:list': true,
    'division:detail': true,
    'division:create': true,
    'division:edit': true,
    'user:list': true,
    'user:create': false,
    'user:edit': true,
    'user:group-policy-info-edit': false,
    'user:bulk-import': true,
    'user:bulk-edit': true,
    'asset:list': true,
    'asset:create': true,
    'asset:edit': true,
    'asset:bulk-edit': true,
    'asset:bulk-import': true,
    'policy:list': true,
    'policy:detail': true,
    'policy:create': true,
    'policy:edit': true
  },
  helpLink: 'https://zonarsystemshelp.zendesk.com/hc/en-us',
  productWhitelist: [
    '8a90c92b-9b40-4ff9-887e-9e55347d5da1', // Essentials
    'bf4730fe-237e-4f8d-86a6-8ce088eba754', // Remote Monitoring
    '4fc734fb-cacf-4d13-abbf-bad3157ffc0b', // Coach
    '9f4c478d-2d0f-45d3-906a-02e206b764fa', // Emissions Check
    '9fb28dba-e4e6-4fab-bd0f-89e30754529e', // Evir Mobile
    'c816fae9-d1d8-4fcd-a158-33bced444272', // Logs
    'b7f4d4bf-01b5-48ad-86c7-b4ecb570c05e', // Z Pass
  ]
};
