import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import {
  IApplication,
  ICreateDivisionParams,
  IDivision,
  IGetCompanyLocationsParams,
  IGetDivisionParams,
  ILocation,
  IProduct,
} from '@app/core/models/company.model';
import { environment as env } from '@environments/environment';
import { ICompany, ICompanyAddress, ICreateCompanyParams, IGetCompanyDivisionsParams, IGetCompanyParams } from '@app/core/models/company.model';
import { IDotNumber, IGetDotNumberParams } from '@app/core/models/company.model';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import { ICompanyMode } from '@app/core/models/user-company.model';
import { GetCompanyModePayload } from '@app/shared/data-stores/app/app-data-store.interface';

@Injectable({
  providedIn: 'root'
})
export class CoreCompanyApiService {
  private COMPANY_RESOURCE = 'companies';
  private DIVISIONS_RESOURCE = 'divisions';
  private DOT_NUMBERS_RESOURCE = 'dotnumbers';
  private ADDRESSES_RESOURCE = 'addresses';
  private LOCATIONS_RESOURCE = 'locations';
  private PRODUCTS_RESOURCE = 'products';
  private USERS_RESOURCE = 'users';
  private APPLICATIONS_RESOURCE = 'applications';


  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }


  getCompany(id: string) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${id}`;
    return this.http.get(url) as Observable<ICompany>;
  }

  getCompanies(params?: IGetCompanyParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  getCompanyDivisions(companyId: string, params?: IGetCompanyDivisionsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  getCompanyChildrenDivisions(companyId: string, divisionId: string, params?: IGetCompanyDivisionsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}/${divisionId}/children`;
    return this.entityApiBaseService.getData(url, params);
  }

  getCompanyDivision(companyId: string, divisionId: string, params?: IGetCompanyDivisionsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}/${divisionId}`;
    return this.entityApiBaseService.getData(url, params);
  }

  getDivisions(params?: IGetDivisionParams) {
    const url = `${env.coreEntityApiBase.url}/${this.DIVISIONS_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  getDivisionById(id: string) {
    const url = `${env.coreEntityApiBase.url}/${this.DIVISIONS_RESOURCE}/${id}`;
    return this.http.get(url) as Observable<IDivision>;
  }

  getDotNumbers(companyId: string, params?: IGetDotNumberParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DOT_NUMBERS_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  createCompany(params: ICreateCompanyParams) {
    if (!env.production) {
      console.log(`Creating company with name ${params.name} and anonymized data is set to ${params.anonymizedData} and language is ${params.defaultLanguage}`);
    }
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}`;
    return this.http.post(url, params, { headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  createDivision(companyId: string, params: ICreateDivisionParams): Observable<HttpResponse<any>> {
    if (!env.production) {
      console.log(`Creating division for companyId ${companyId} and legacy account code is set to  ${params.legacyAccountCode}`);
    }
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getCompanyDivisionCount(companyId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}`;
    return this.http.head(url, { observe: 'response' });
  }
  createCompanyAddress(companyId: string, params: Partial<ICompanyAddress>) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.ADDRESSES_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getAllCompanyAddresses(companyId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.ADDRESSES_RESOURCE}`;
    return this.entityApiBaseService.getData(url);
  }

  updateCompany(companyId: string, params: Partial<ICompany>) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  updateCompanyAddress(companyId: string, addressId: string, params: Partial<ICompanyAddress>) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.ADDRESSES_RESOURCE}/${addressId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getCompanyLocations(companyId: string, params?: IGetCompanyLocationsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.LOCATIONS_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, params);
  }
  createLocation(companyId: string, params: Partial<ILocation>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.LOCATIONS_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getLocation(companyId: string, locationId: string): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.LOCATIONS_RESOURCE}/${locationId}`;
    return this.http.get(url, { observe: 'response' });
  }

  updateLocation(companyId: string, locationId: string, location: Partial<ILocation>) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.LOCATIONS_RESOURCE}/${locationId}`;
    return this.http.patch(url, location, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getSingleDivision(companyId: string, divisionId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}/${divisionId}`;
    return this.http.get(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  createDivisionDot(companyId: string, params): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DOT_NUMBERS_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response' });
  }

  getCompanyDotList(companyId: string): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DOT_NUMBERS_RESOURCE}`;
    return this.http.get(url, { observe: 'response' });
  }

  updateDivision(companyId: string, divisionId: string, params: Partial<IDivision>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}/${divisionId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  updateDivisionDot(companyId: string, dotId: string, params: Partial<IDotNumber>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DOT_NUMBERS_RESOURCE}/${dotId}`;
    return this.http.patch(url, params, { observe: 'response' });
  }

  getAllCompanyDivisions(companyId: string, params?) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, params);
  }

  getCompanyProducts(companyId: string, params?) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.PRODUCTS_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, params);
  }

  createCompanyAssociatedProduct(companyId: string, params): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.PRODUCTS_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  deleteCompanyAssociatedProduct(companyId: string, params): Observable<any> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.PRODUCTS_RESOURCE}`;
    return this.http.delete(url, { body: params, observe: 'response' });
  }

  updateCompanyUsers(companyId: string, userId: string, params: any): Observable<any> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.USERS_RESOURCE}/${userId}`;
    return this.http.patch(url, params);
  }

  createCompanyUsers(companyId: string, params: any): Observable<any> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.USERS_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response' });
  }

  getCompanyUsers(companyId: string, params: any): Observable<any> {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.USERS_RESOURCE}`;
    return this.http.get(url, { observe: 'response', params });
  }

  getProductApplications(productId: string): Observable<IApplication[]> {
    const url = `${env.coreEntityApiBase.url}/${this.PRODUCTS_RESOURCE}/${productId}/${this.APPLICATIONS_RESOURCE}`;
    return this.http.get(url) as Observable<IApplication[]>;
  }
}
