<div class="dropdown-panel-container" fxLayout="row wrap" fxLayoutAlign=" center">
  <ng-container *ngFor="let group of getFormGroups(); index as i; let last = last" [formGroup]="group">
    <div fxLayout="column" class="container">
      <div fxLayout="row">
        <div class="error-box-container" [ngClass]="group.hasError('roleEmpty') ? 'error-box': ''">
          <div *ngIf="group.hasError('roleEmpty')" fxLayout="row" fxLayoutAlign="start start" class="error-box-content">
            <img class="error-icon" [src]="'assets/icons/error-icon.svg'" />
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="title">{{ translations.user.emptyError?.title | translate }}
              </span>
              <span>{{ translations.user.emptyError?.description | translate }}</span>
              <span class="actions">{{ translations.user.emptyError?.action | translate }}</span>
              <span class="char">{{ translations.user.emptyError?.selectRoleText | translate }}</span>
              <span class="char">{{ translations.user.emptyError?.editRoleText | translate }}</span>
              <span class="char">{{ translations.user.emptyError?.createRoleText | translate }}</span>
              <span class="char">{{ translations.user.emptyError?.deleteRoleText | translate }}</span>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
            <div fxFlex="50" fxLayout="column">
              <div class="first-column zui-dropdown">
                <zui-searchable-dropdown [placeholder]="'placeholder'" [ngClass]="group.hasError('roleEmpty') ? 'error-form-field' : ''"
                  [options]="group.get('policyOptions')?.value" [label]="translations.user.roleLabel | translate"
                  [selectOptionsControlName]="'policy'" [searchFormControlName]="'primarySearchForm'"
                  [isMultiple]="false" [z_formGroup]="group" [sortByStringTitle]="true" [z_disabled]="false"
                  (click)="openDropdown(group, mode='policy')">
                </zui-searchable-dropdown>

                <mat-error class="empty-error-message" *ngIf="group.hasError('roleEmpty')">
                  {{ translations.user.emptyError?.roleEmptyErrorMessage | translate }}
                </mat-error>
              </div>

              <!-- Role View button -->
              <div class="view-button first-column">
                <button mat-stroked-button type="button" [disabled]="!group.controls.policy.value"
                  (click)="onViewPolicyClick(group.controls.policy.value)">
                  {{ translations.user.viewButton | translate }}
                </button>
              </div>
            </div>

            <div [ngStyle]="{'margin-top': media.isActive('xs') ? '0.625rem': 0}" fxFlex="50" fxLayout="column">
              <div class="second-column zui-dropdown">
                <zui-searchable-dropdown [placeholder]="'placeholder'" [ngClass]=""
                  [options]="group.get('secondaryOptions')?.value"
                  [label]="translations.user.accessLabel | translate:getParsedKeyWords() | titlecase"
                  [selectOptionsControlName]="'tenant'" [searchFormControlName]="'secondarySearchForm'"
                  [isMultiple]="false" [z_formGroup]="group" [sortByStringTitle]="false"
                  (click)="openDropdown(group, mode='tenant')" [z_disabled]="group.hasError('roleEmpty')">
                </zui-searchable-dropdown>
              </div>
              <!-- Access View button -->
              <div class="view-button second-column">
                <button mat-stroked-button type="button"
                  [disabled]="!group.controls.tenant.value || !dialogData?.length || group.hasError('roleEmpty')"
                  (click)="onTenantView(group.controls.tenant.value)">
                  {{ translations.user.viewButton | translate }}
                </button>
              </div>
            </div>

          </div>
        </div>
        <div *ngIf="!media.isActive('xs')" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center end">
          <mat-icon [ngClass]="hasDisabled(group.controls) ? 'disabled-color' : ''" [fxFlex]="group.hasError('roleEmpty') ? 100 : 50"
            fxLayoutAlign="start center" class="mat-icon material-icons mat-icon-no-color delete-icon"
            (click)="deleteGroup(i, group.controls)">
            delete
          </mat-icon>
        </div>

      </div>

      <div fxLayout="column" class="error-container">
        <mat-error *ngIf="group.hasError('unselected')">
          {{ translations.user.unselectedGroupWarning | translate:getParsedKeyWords() }}
        </mat-error>
        <mat-error *ngIf="group.hasError('duplicated')">
          {{ translations.user.duplicatedGroupWarning | translate:getParsedKeyWords() }}
        </mat-error>

        <mat-divider class="divider"></mat-divider>

        <div *ngIf="media.isActive('xs')" fxLayout="row" fxLayoutAlign="end end">
          <button *ngIf="last" mat-button class='button-add-group' (click)="addNewGroup()">
            <mat-icon matPrefix class="mat-icon material-icons new-group-icon">add_circle</mat-icon>
            <span>
              {{translations.user.addTextIcon | translate:getParsedKeyWords() }}
            </span>
          </button>

          <button mat-button class='button-add-group remove-icon' [disabled]="hasDisabled(group.controls)"
            (click)="deleteGroup(i, group.controls)">
            <mat-icon class="mat-icon material-icons delete-icon-mobile">
              do_not_disturb_on
            </mat-icon>
            <span>Remove</span>
          </button>
        </div>
      </div>
    </div>

  </ng-container>
</div>

<button *ngIf="!media.isActive('xs') || groups?.length === 0" mat-button class='button-add-group'
  (click)="addNewGroup()">
  <mat-icon matPrefix class="mat-icon material-icons new-group-icon">add_circle</mat-icon>
  <span>
    {{
    (media.isActive('xs') ? translations.user.addTextIcon :
    ( groups?.length === 0 ? translations.user.addFirstGroup : translations.user.addMoreGroup)) |
    translate:getParsedKeyWords()
    }}
  </span>
</button>