import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ParamsURL } from "@app/core/consts/company.const";
import { EGroupPolicySetting } from "@app/core/consts/group.const";
import { Translations } from "@app/core/services/i18n/translations.service";
import { SettingsService } from "@app/core/services/settings.service";
import { parseUUIDByURL } from "@app/core/utils";
import { TranslateService } from "@ngx-translate/core";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { getAllCompanyDivisions } from "@app/shared/data-stores/divisions/divisions-data-store.action";
import { selectCompanyDivisions } from "@app/shared/data-stores/divisions/divisions-data-store.selects";
import { DivisionType } from "@app/core/consts/division.const";
import { SettingApiService } from "@app/core/services/api/setting-api.service";
import { PermissionsService } from "@zonar-ui/auth";
import { CoreUserApiService } from "@app/core/services/api/core-user-api.service";

@Component({
  selector: 'app-migration-indeterminate-dialog',
  templateUrl: './migration-indeterminate-dialog.component.html',
  styleUrls: ['./migration-indeterminate-dialog.component.scss']
})
export class MigrationIndeterminateDialogComponent implements OnInit, OnDestroy {
  companyId: string = '';
  apiFetchInterval;
  translated: any = {};

  private onApiFetchIntervalBegin$ = new Subject<void>();
  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MigrationIndeterminateDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    private settingsService: SettingsService,
    private router: Router,
    private store: Store<any>,
    private settingApiService: SettingApiService,
    private permissionsService: PermissionsService,
    private coreUserApiService: CoreUserApiService,
  ) { }

  ngOnInit(): void {
    this.companyId = parseUUIDByURL(this.router.url, ParamsURL.COMPANY) || this.data?.companyId;

    switch (this.data?.mode) {
      case EGroupPolicySetting.ERROR:
        this.snapshotInProgress();
        break;
      case EGroupPolicySetting.SNAPSHOTTING_PROFILES:
        this.snapshotInProgress();
        this.startApiFetchInterval();
        break;
      case EGroupPolicySetting.ENABLING:
        this.migrating();
        this.startEnabling();
        break;
      default:
        this.onDialogClose();
        break;
    }
  }

  snapshotInProgress() {
    combineLatest([
      this.coreUserApiService.countUser({companyId: this.companyId}),
      this.translateService.get('companyMigration.snapshotDialog'),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([res, translated]) => {
        if (res) {
          const totalCount = +res.headers?.get('x-total-count') || 0;
          this.translated = {
            title: totalCount ? (`${translated.title} ${totalCount} ${totalCount > 1 ? translated.users : translated.user}`) : `${translated.title} ${translated.user}`,
            description: translated.description,
            progressBarDescription: translated.userImport,
          };
        }
      });
  }

  migrating() {
    this.translateService.get('companyMigration.migratingDialog')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((translated) => {
        this.translated = {
          title: translated.title,
          description: translated.description,
          progressBarDescription: translated.updatingUsers,
        };
      });
  }

  startApiFetchInterval() {
    this.apiFetchInterval = setInterval(() => {
      this.onApiFetchIntervalBegin$.next();
      this.getCompanyGroupPolicySetting();
    }, this.data.apiFetchIntervalTime);
  }

  startEnabling() {
    this.store.dispatch(getAllCompanyDivisions({
      payload: {
        companyId: this.companyId,
        params: { type: DivisionType.LEGACY }
      }
    }));

    combineLatest([
      this.store.select(selectCompanyDivisions),
      this.permissionsService.getUserProfiles()
    ]).pipe(takeUntil(this.onDestroy$)).subscribe(([divisions, profiles]) => {
      if (divisions.length && profiles.length) {
        let legacyAccountCodes: Array<string> = [...new Set(divisions.map(div => div.legacyAccountCode))]; // Remove duplicate account codes
        this.settingApiService.completeMigrationProcess(this.companyId, legacyAccountCodes, profiles[0].id).pipe(takeUntil(this.onDestroy$))
          .subscribe((res) => {
            this.dialogRef.close({ status: 200 });
          }, (error) => {
            this.dialogRef.close({ status: 400 });
          });
      }
    });
  }

  getCompanyGroupPolicySetting() {
    this.settingsService.getCompanyMigrationStatus(this.companyId).pipe(takeUntil(this.onApiFetchIntervalBegin$))
      .subscribe((status) => {
        if (status !== this.data.mode) {
          this.dialogRef.close(true);
        }
      }, (error) => {
        // TODO: Handle error
      });
  }

  onDialogClose() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    clearInterval(this.apiFetchInterval);
    this.onApiFetchIntervalBegin$.next();
    this.onApiFetchIntervalBegin$.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
