import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ECardListCategory } from "@app/core/consts/card-list.const";
import { PersistTooltipService } from "@app/core/services/persist-tooltip.service";

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() category: string = '';
  @Input() data;

  cardCategory = ECardListCategory;

  constructor(
    private router: Router,
    private tooltipService: PersistTooltipService
  ) {}

  onNavigate(link) {
    this.router.navigate([link]);
  }
}
