/* angular */
import { IApplication, IProduct } from '@app/core/models/company.model';
import { Application } from '@app/core/models/user.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  InitProducts = '[PRODUCTS] InitProducts',
  GetManagedApplications = '[PRODUCTS] GetManagedApplications',
  GetManagedApplicationsSuccessful = '[PRODUCTS] GetManagedApplicationsSuccessful',
  GetManagedApplicationsFailure = '[PRODUCTS] GetManagedApplicationsFailure',
  GetAllProducts = '[PRODUCTS] GetAllProducts',
  GetAllProductsSuccessful = '[PRODUCTS] GetAllProductsSuccessful',
  GetAllProductsFailure = '[PRODUCTS] GetAllProductsFailure',
  GetAllApplications = '[PRODUCTS] GetAllApplications',
  GetAllApplicationsSuccessful = '[PRODUCTS] GetAllApplicationsSuccessful',
  GetAllApplicationsFailure = '[PRODUCTS] GetAllApplicationsFailure',
};

/* actions */
export const initProducts = createAction(ActionTypes.InitProducts);
export const getManagedApplications = createAction(ActionTypes.GetManagedApplications, props<{ payload: { companyId: string } }>());
export const getManagedApplicationsSuccessful = createAction(ActionTypes.GetManagedApplicationsSuccessful, props<{ payload: { managedApplications: Application[] } }>());
export const getManagedApplicationsFailure = createAction(ActionTypes.GetManagedApplicationsFailure, props<{ payload: { error: any } }>());
export const getAllProducts = createAction(ActionTypes.GetAllProducts);
export const getAllProductsSuccessful = createAction(ActionTypes.GetAllProductsSuccessful, props<{ payload: {allProducts: IProduct[]} }>());
export const getAllProductsFailure = createAction(ActionTypes.GetAllProductsFailure, props<{ payload: {error: any} }>());
export const getAllApplications = createAction(ActionTypes.GetAllApplications);
export const getAllApplicationsSuccessful = createAction(ActionTypes.GetAllApplicationsSuccessful, props<{ payload: {allApplications: IApplication[]} }>());
export const getAllApplicationsFailure = createAction(ActionTypes.GetAllApplicationsFailure, props<{ payload: {error: any} }>());
