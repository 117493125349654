<div class="single-dropdown-panel" fxLayout="column" fxLayoutAlign="start start">
  <span class="title">
    {{ data?.title }}
  </span>
  <div class="description" [innerHTML]="data?.description"></div>
  <div class="form">
    <div class="form-group" *ngFor="let form of data?.formGroups?.controls; index as i; let last = last" [formGroup]="form" fxLayout="row" fxLayoutAlign="start center">
      <span class="group-title" fxFlex="36">
        {{ form?.get('staticValues')?.value?.name }}:
      </span>
      <zui-searchable-dropdown fxFlex="54"
        [z_formGroup]="form"
        [options]="form.get('options')?.value"
        [selectOptionsControlName]="'selectedOption'"
        [isMultiple]="false"
        [z_disabled]="false"
        [label]="data?.formDropdownLabel">
      </zui-searchable-dropdown>
      <span class="group-clear" fxFlex="10">
        <div class="clear-text" *ngIf="data?.formClearLabel && form?.get('selectedOption')?.value" (click)="onClearButtonClick(form)">
          {{ data.formClearLabel }}
        </div>
      </span>
    </div>
  </div>
  
</div>
