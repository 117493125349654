import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplication, IProduct } from '@app/core/models/company.model';
import { environment as env } from '@environments/environment';
import { Observable } from 'rxjs';
import { EntityApiBaseService } from './entity-api-base.service';

@Injectable({
  providedIn: 'root'
})
export class CoreProductApiService {

  private APPLICATION_RESOURCE = 'applications';
  private PRODUCTS_RESOURCE = 'products';

  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }

  getApplicationById(applicationId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.APPLICATION_RESOURCE}/${applicationId}`;
    return this.http.get(url, { observe: 'response' });
  }

  getApplications():Observable<IApplication[]> {
    const url = `${env.coreEntityApiBase.url}/${this.APPLICATION_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, {}) as Observable<IApplication[]>;
  }

  getProducts(): Observable<IProduct[]> {
    const url = `${env.coreEntityApiBase.url}/${this.PRODUCTS_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, {}) as Observable<IProduct[]>;
  }

}
