import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SnackBarData, SnackBarType } from '@app/core/models/snack-bar.model';

@Component({
  selector: 'app-snack-banner',
  templateUrl: './snack-banner.component.html',
  styleUrls: ['./snack-banner.component.scss']
})
export class SnackBannerComponent implements OnChanges {

  @Input() data: SnackBarData = {};
  @Output() actionButtonClick = new EventEmitter<any>();
  showButton: boolean = false;
  showActionButton: boolean = false;
  buttonText: string = '';
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.showButton = !this.data?.hideButton;
      this.showActionButton = !!this.data.buttonText;
      this.buttonText = this.data.buttonText || '';
    }
  }

  handleButtonClick(): void {
    this.actionButtonClick.emit(true);
  }

  get snackBarType() {
    return SnackBarType;
  }
}
