export enum SnackBarType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error'
}

export interface SnackBarData {
  type?: SnackBarType;
  title?: string;
  text?: string;
  buttonText?: string;
  buttonClassName?: string;
  hideButton?: boolean;
}


