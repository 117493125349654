import { IDriver, IDriverProfile } from "@app/core/models/driver.model";
import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  GetDriverProfilesByUserId = '[DRIVERS] GetDriverProfilesByUserId',
  GetDriverProfilesByUserIdSuccessful = '[DRIVERS] GetDriverProfilesByUserIdSuccessful',
  GetDriverProfilesByUserIdFailure = '[DRIVERS] GetDriverProfilesByUserIdFailure',
 }

export const getDriverProfilesByUserId = createAction(ActionTypes.GetDriverProfilesByUserId, props<{ payload: { userId: string } }>());
export const getDriverProfilesByUserIdSuccessful = createAction(ActionTypes.GetDriverProfilesByUserIdSuccessful, props<{ payload: { driverProfiles: IDriverProfile } }>());
export const getDriverProfilesByUserIdFailure = createAction(ActionTypes.GetDriverProfilesByUserIdFailure, props<{ payload: { error: any } }>());
