import { PermissionsService } from '@zonar-ui/auth';
import { Component, OnInit, OnDestroy, Renderer2, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject, BehaviorSubject, combineLatest, concat, of } from 'rxjs';
import { PendoService, ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { FaviconsService } from '@zonar-ui/core';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';

import { I18nService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { concatMap, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DataDogService } from './core/services/data-dog.service';
import { PreviousRouteService } from './core/services/previous-route.service';
import { UserDataService } from '@app/core/services/data/user-data.service';
import { PAGE_URL } from '@app/core/consts/url.const';
import { environment } from '@environments/environment';
import { FeatureKeys, MigrationDialogIds } from '@app/core/consts/app.const';
import { LoadingService } from './core/services/loading.service';
import { FeatureToggleService } from './shared/services/feature-toggle.service';
import { SidenavFooterConfig, SidenavHeaderConfig, SidenavMenuConfig } from './shared/config/sidenav.config';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import { selectGroupPolicySetting } from './shared/data-stores/setting/setting-data-store.selects';
import { Store } from '@ngrx/store';
import { EGroupPolicySetting } from './core/consts/group.const';
import { DialogService } from './core/services/dialog.service';
import { RegionParserService } from './core/services/region-parser.service';
import { selectCompanyId } from './shared/data-stores/app/app-data-store.selects';
import { MatDialog } from '@angular/material/dialog';
import { setGroupPolicySetting } from './shared/data-stores/setting/setting-data-store.action';
import { getGroupPolicySetting as getCompanyMigrationStatus } from '@app/shared/data-stores/setting/setting-data-store.action';
import { setCompanyId } from './shared/data-stores/app/app-data-store.actions';
import { parseUUIDByURL } from './core/utils';
import { ParamsURL } from './core/consts/company.const';
import { ChangeCompanyService } from '@zonar-ui/sidenav';

export interface Link {
  title: string;
  url: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  hasShowChangeCompany = false;
  translationsNotLoaded = true;
  translateSubscription: Subscription;
  title = 'self-service-app';
  hasPageNotFound = false;
  footerUrls = [];
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  featureKeys = FeatureKeys;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    lockExpansion: false,
    useDefaultHelpItem: false,
    hideChangeCompanyButton: false,
    hideCompanyNameInCompactMode: false,
    footerOffset: false,
    suppressChangeCompanyDialog: false,
    disableBackdropClose: true
  };
  sidenavHeader = SidenavHeaderConfig;
  sidenavMenu = SidenavMenuConfig;
  sidenavFooter = SidenavFooterConfig;
  hasLoadData = false;
  isFetchPermsSuccess = false;
  isCloseAllDialog = false;
  initUrl = '';
  initCompanyId = '';
  private onDestroy$ = new Subject<void>();
  constructor(
    private datadog: DataDogService,
    private favicon: FaviconsService,
    private zuiAnaltyics: ZonarUiAnalyticsService,
    private pendoService: PendoService,
    public previous: PreviousRouteService,
    private i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    private router: Router,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private userDataService: UserDataService,
    public featureToggleService: FeatureToggleService,
    private renderer: Renderer2,
    private _loading: LoadingService,
    private store: Store<any>,
    private dialogService: DialogService,
    private changeCompanyService: ChangeCompanyService,
    public regionParserService: RegionParserService,
    private matDialog: MatDialog,
    private gtcxMobileHelperService: GTCxMobileHelperService,
  ) {
    if (this.translationsNotLoaded) {
      this.translateSubscription = this.translateService
        .use(this.i18nService.getLanguageInUse()).subscribe(() => this.translationsNotLoaded = false);
    }
    this.renderer.addClass(document.body, 'zonar-default-theme');
  }

  ngOnInit() {
    this.checkPageNotFound();
    this.getCurrentUserLogin();
    this.zuiAnaltyics.addGtmToDom();
    this.loading$ = this._loading.loadingSub;
    // when we initialize pendo, some of the api calls require a zoner owner id, which can only be attached to the
    // request after the permissions are loaded. therefore, we must wait to initialize pendo until after the
    // permissions are loaded. ultimately, this logic should be handled in the pendo service itself as described here:
    // https://jira.zonarsystems.net/browse/ZTT-1015\
    this.permissionsService.getPermissions().pipe(filter(permissions => Boolean(permissions))).subscribe(() => {
      this.pendoService.initialize();
    });

    this.permissionsService.getIsFetchPermsSuccess().pipe(filter(isFetchPermsSuccess => isFetchPermsSuccess !== null)).subscribe((isFetchPermsSuccess) => {
      this.isFetchPermsSuccess = isFetchPermsSuccess;
    });

    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      concatMap((event: any, index) => {
        if (event?.url && index === 0) {
          const companyIdRoute = parseUUIDByURL(event.url, ParamsURL.COMPANY);
          console.log('url', event.url);
          if (companyIdRoute) {
            this.initUrl = event.url;
            this.initCompanyId = companyIdRoute;
            this.changeCompanyService.changeSelectedCompany(companyIdRoute);
          }
        }
        return of(event);
      }),
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnaltyics.pushTag(tag);
      }),
    ).subscribe();

    this.checkIsZonarUser();
    this.adjustSidenav();
    this.registerCompanyChange();
    this.showMigrationStartDialog();
  }

  adjustSidenav() {
    this.matDialog.afterOpened.subscribe(dialog => {
      if (Object.values(MigrationDialogIds).includes(dialog?.id)) {
        this.makeSidenavOnTop();
      } else {
        this.resetSideNavPosition();
      }
    });
  }

  checkIsZonarUser() {
    this.permissionsService.getIsZonarUser().subscribe(isZonar => {
      this.hasShowChangeCompany = !isZonar;
    });
  }

  registerCompanyChange() {
    this.store.select(selectCompanyId)
      .pipe(
        filter(companyId => Boolean(companyId)),
        takeUntil(this.onDestroy$),
      ).subscribe(companyId => {
        // Get status of group policy migration
        this.store.dispatch(getCompanyMigrationStatus({ payload: { companyId } }));

        // Init mobile service each of switching company
        this.gtcxMobileHelperService.setCurrentCompany(companyId);
      });
  }

  showMigrationStartDialog() {
    const translatePath = 'companyMigration.migrationStartDialog';
    combineLatest(
      [
        this.store.select(selectGroupPolicySetting),
        this.translateService.get(translatePath),
        this.store.select(selectCompanyId)
      ]
    ).pipe(
      takeUntil(this.onDestroy$),
      filter(([groupSetting, translated, companyId]) => Boolean(translated) && Boolean(companyId)))
      .subscribe(([groupSetting, translated, companyId]) => {
        translated = this.regionParserService.getParsedBucketKeyWords(
          translatePath, Object.keys(translated));
        this.hasLoadData = false;
        if (groupSetting && companyId) {
          switch (groupSetting) {
            case EGroupPolicySetting.DISABLED:
              this.hasLoadData = true;
              break;
            case EGroupPolicySetting.SNAPSHOTTING_PROFILES:
              this.companySnapshotInProgress(companyId);
              break;
            case EGroupPolicySetting.PENDING:
              this.hasLoadData = true;
              break;
            case EGroupPolicySetting.ENABLING:
              // TODO: if the settings value from the API is 'ENABLING' then show the enabling migration dialog
              this.hasLoadData = true;
              break;
            case EGroupPolicySetting.ENABLING_ERROR:
              this.hasLoadData = true;
              break;
            case EGroupPolicySetting.ENABLED:
              this.hasLoadData = true;
              break;
            case EGroupPolicySetting.ERROR:
              this.companySnapshotInProgressForError();
              break;
            default:
              break;
          }
        } else {
          this.hasLoadData = true;
        }
      });
  }

  companySnapshotInProgress(companyId: string) {
    this.dialogService.showIndeterminateDialog({
      mode: EGroupPolicySetting.SNAPSHOTTING_PROFILES,
      // TODO update to 15000
      apiFetchIntervalTime: 1000,
      progressBarIcon: 'upload_file',
      companyId
    }, '37.5rem', '21.5rem').subscribe((result) => {
      if (result) {
        this.hasLoadData = true;
        this.store.dispatch(setGroupPolicySetting({ payload: { setting: EGroupPolicySetting.PENDING } }));
      }
    });
  }

  companySnapshotInProgressForError() {
    this.dialogService.showIndeterminateDialog({
      mode: EGroupPolicySetting.ERROR,
      progressBarIcon: 'upload_file',
    }, '37.5rem', '21.5rem').subscribe((result) => {
      // if (result) {
      //   this.hasLoadData = true;
      //   this.store.dispatch(setGroupPolicySetting({ payload: { setting: EGroupPolicySetting.PENDING } }));
      // }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.makeSidenavOnTop();
  }

  resetSideNavPosition() {
    setTimeout(() => {
      const overlayContainer: any = document.querySelector('.cdk-overlay-container');
      if (overlayContainer && overlayContainer.style) {
        overlayContainer.style.setProperty('z-index', 1000);
      }
    });
  }

  makeSidenavOnTop() {
    setTimeout(() => {
      const sidenav: any = document.querySelector('.sidenav-inner-container');
      if (sidenav && sidenav.style) {
        sidenav.style.setProperty('z-index', 200);
      }

      const sidenavContainer: any = document.querySelector('.sidenav-container');
      if (sidenavContainer && sidenavContainer.style) {
        sidenavContainer.style.setProperty('z-index', 'unset');
      }
      const matDrawer: any = document.querySelector('.mat-drawer.mat-drawer-side');
      if (matDrawer && matDrawer.style) {
        matDrawer.style.setProperty('z-index', 200);
      }
      const matDrawerContent: any = document.querySelector('.mat-drawer-content');
      if (matDrawerContent && matDrawerContent.style) {
        matDrawerContent.style.setProperty('z-index', 'unset');
      }
      const sidenavToolbar: any = document.querySelector('.header-bar-sidenav-toolbar');
      if (sidenavToolbar && sidenavToolbar.style) {
        sidenavToolbar.style.setProperty('z-index', 100);
      }
      const overlayContainer: any = document.querySelector('.cdk-overlay-container');
      if (overlayContainer && overlayContainer.style) {
        overlayContainer.style.setProperty('z-index', 99);
      }
    });
  }

  initFooterUrls() {
    this.translateService.get('apps.footer')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(translated => {
        if (typeof translated === 'object') {
          this.footerUrls = [
            {
              title: translated.supportLink,
              url: environment.footerUrls.supportLink,
            },
            {
              title: translated.dataDisclosureLink,
              url: environment.footerUrls.dataDisclosureLink,
            }
          ];
        }
      });
  }

  navigateCompanyByURL(companyId, tab = null) {
    this.router.navigateByUrl('/refresh-component', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/company/${companyId}`], { queryParams: tab ? { tab } : null });
    });
  }

  getCurrentUserLogin() {
    this.permissionsService.getUsers().pipe(takeUntil(this.onDestroy$)).subscribe(users => {
      if (users && users.length) {
        this.userDataService.setCurrentUserLogin(users[0]);
      }
    });
  }

  checkPageNotFound() {
    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationEnd) => {
      this.hasPageNotFound = event.url === PAGE_URL.PAGE_NOT_FOUND || event.url === PAGE_URL.NO_PERMISSION;
    });
  }

  onCloseSelectedCompany(event) {
    this.makeSidenavOnTop();
  }

  switchToSelectedCompany(incomingCompany: any) {
    const companyId = incomingCompany?.value;
    if (companyId) {
      this.isCloseAllDialog = true;
      this.matDialog.closeAll();
      this.matDialog.afterAllClosed.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        if (this.isCloseAllDialog) {
          this.sidenavParams.disableBackdropClose = !!companyId ? false : true;
          this.isCloseAllDialog = false;
        }
      });

      this.store.dispatch(setCompanyId({ payload: { id: companyId } }));
      if (this.initUrl && this.initCompanyId && companyId === this.initCompanyId) {
        this.router.navigate([this.initUrl]);
        this.initUrl = '';
      } else {
        this.router.navigate([`company/${companyId}`]);
      }
    }
  }

  onSideNavMobileMenuButtonToggled() {
    this.sidenavParams.mobileOpened = true;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.translateSubscription.unsubscribe();
  }
}

