import { Injectable } from '@angular/core';
import { EGroupPolicyStatus } from '@app/core/consts/app.const';
import { ETenantType } from '@app/core/consts/tenant.const';
import { ICreatePolicyGrantsParams, ICreatePolicyParams, IPolicy } from '@app/core/models/policy.model';
import { Application } from '@app/core/models/user.model';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PolicyDataService {
  companyLevelCapaSelectedList = [];
  allRoleCompanyLevelCapaList = [];

  constructor() { }

  buildDataToCreatePolicy(confirmData, companyId, applications): ICreatePolicyParams {
    const { dataChanged, name } = confirmData;
    return {
      name,
      companyId,
      grants: this.buildGrants(dataChanged, applications),
      status: EGroupPolicyStatus.ACTIVE,
    };
  }

  buildGrants(dataChanged, applications): Array<ICreatePolicyGrantsParams> {
    const grants = [];
    applications.forEach(app => {
      const data = {
        application: '',
        roles: []
      };
      const appChanged = dataChanged.find(id => id === app.id);
      // data have appId
      if (appChanged) {
        data.application = appChanged;
        app.roles.forEach(role => {
          const roleChanged = dataChanged.find(id => id === role.id);
          if (roleChanged) {
            data.roles.push(roleChanged);
          }
        });

        // select only application
        if (!data.roles?.length) {
          data.roles = app.roles.map(role => role.id);
        }

        grants.push(data);
      } else { // case haven't appId
        app.roles.forEach(role => {
          const roleChanged = dataChanged.find(id => id === role.id);
          if (roleChanged) {
            data.application = app.id;
            data.roles.push(roleChanged);
          }
        });
        // push correct data
        if (data.application) {
          grants.push(data);
        }
      }
    });

    return grants;
  }

  initAppsSelectedAndDisabled(initPolicyInfo, appTreeNodes) {
    const result = cloneDeep(appTreeNodes) || [];
    result.forEach(data => {
      const grantExisted: any = initPolicyInfo.grants.find(grant => grant.application.id === data.id);
      if (grantExisted && grantExisted.roles?.length === data.roles?.length) {
        data.selected = true;
      } else {
        if (!data.roles) {
          if (this.isCompanyLevelCapabilities(data)) {
            data.disabled = true;
          }

          const roleExisted: any = initPolicyInfo.grants.find(grants => grants.roles.find(resRole => resRole.id === data.id));
          if (roleExisted) {
            data.selected = true;
          }
        } else {
          if (data.roles.length && data.roles.every(role => this.isCompanyLevelCapabilities(role))) {
            data.disabled = true;
          }
        }
      }
    });
    return result;
  }

  isCompanyLevelCapabilities(role) {
    return role.allowedTenants.includes(ETenantType.SINGLE_COMPANY) && !role.allowedTenants.includes(ETenantType.DIVISION_LIST);
  }

  initApplicationSelected(initPolicyInfo, appTreeNodes) {
    const result = cloneDeep(appTreeNodes) || [];
    initPolicyInfo.grants.forEach(grant => {
      const appExisted: any = result.find(app => grant.application.id === app.id);
      if (appExisted && grant.roles.length === appExisted.roles.length) {
        appExisted.selected = true;
      } else {
        grant.roles.forEach(role => {
          const roleExisted: any = result.find(resRole => !!resRole.parentId && resRole.id === role.id);
          if (roleExisted) {
            roleExisted.selected = true;
          }
        });
      }
    });
    return result;
  }

  buildCompanyLevelCapaSelectedList(policy, appList) {
    this.companyLevelCapaSelectedList = [];
    this.allRoleCompanyLevelCapaList = [];

    appList.forEach(app => {
      this.allRoleCompanyLevelCapaList = this.allRoleCompanyLevelCapaList.concat(app.roles.filter(role => this.isCompanyLevelCapabilities(role)).map(role => role?.id));
    });

    policy.grants.forEach(grant => {
      this.companyLevelCapaSelectedList = this.companyLevelCapaSelectedList.concat(grant.roles.filter(role => this.allRoleCompanyLevelCapaList.find(roleId => roleId === role.id)).map(role => role.id));
    });

  }

  buildInitStateApplications(policy, appList) {
    if (!this.companyLevelCapaSelectedList.length) {
      return this.initAppsSelectedAndDisabled(policy, appList);
    }

    return this.initApplicationSelected(policy, appList);

  }

  updateInitPolicyInfo(policy, groupList, isZonarUser, applications) {
    if (!policy || !groupList) {
      return {};
    }
    const tmpPolicy = cloneDeep(policy);
    const initPolicyInfo = cloneDeep(policy);
    initPolicyInfo.initItemSelected = [];

    tmpPolicy.grants = tmpPolicy.grants?.filter(grant => applications?.find(app => app.id === grant.application?.id));

    tmpPolicy.grants?.forEach(grant => {
      const applicationData = applications.find(appData => appData.id === grant.application.id);

      if (applicationData && grant.roles.length === applicationData.roles.length) {
        initPolicyInfo.initItemSelected.push(grant.application.id, ...grant.roles.map(role => role.id));
      } else {
        initPolicyInfo.initItemSelected.push(...grant.roles.map(role => role.id));
      }

    });

    if (!tmpPolicy?.grants?.length) {
      initPolicyInfo.error = true;
    }

    initPolicyInfo.members = [];
    groupList.filter(item => item.policy?.id === initPolicyInfo.id).forEach(group => {
      const members = group.members.map((userGroup) => {
        return `${userGroup?.firstName ?? ''} ${userGroup?.middleName ?? ''} ${userGroup?.lastName ?? ''}`;
      });
      if (members) {
        initPolicyInfo.members.push(...members);
        // Remove duplicate
        initPolicyInfo.members = [
          ...new Set(
            initPolicyInfo.members
          ),
        ];
      }
    });
    initPolicyInfo.hasDisabled = tmpPolicy.grants?.find(grant => grant.roles.find(role => role.restricted));
    return initPolicyInfo;
  }

  buildAppTreeNode(applications, translated) {
    let appTreeNode = cloneDeep(applications);
    appTreeNode.forEach((app: any) => {
      app.children = [];
      if (app.roles) {
        app.roles = app.roles.filter(role => !role.restricted);
        app.parentId = null;
        app.children = app.roles.map(role => role.id);
        app.roles = app.roles.map(role => ({ ...role, parentId: app.id, children: [] }));
        appTreeNode = appTreeNode.concat(app.roles);

        app.roles.forEach(role => {
          if (this.isCompanyLevelCapabilities(role)) {
            role.name += ` (${translated?.companyLevelText})`;
          }
        });
      }
    });
    return appTreeNode;
  }

  buildPoliciesSuffix(allApplications: Array<Partial<Application>>, appProducts: Array<Partial<Application>>, policies: IPolicy[], companyLevelSuffix: string, emptyRoleSuffix: string) {
    if (!allApplications || !appProducts || !policies) {
      return [];
    }
    const tmpPolicies = cloneDeep(policies);
    const companyLevelRoles = this.getAppRoleList(allApplications) || [];
    const appOfProducts = cloneDeep(appProducts);
    tmpPolicies?.forEach(policy => {
      if (policy?.grants?.find(grant => grant?.roles?.find(role => companyLevelRoles?.includes(role?.id)))) {
        policy.name += ` (${companyLevelSuffix})`;
      }

      if (!policy?.grants?.some(grant => appOfProducts.some(application => application?.id === grant?.application?.id))) {
        policy.name += ` (${emptyRoleSuffix})`;
        policy.isDisabled = true;
        policy.error = { roleEmpty: true };
      }
    });

    return tmpPolicies;
  }

  getAppRoleList(apps): string[] {
    let appRoleList: Array<string> = [];
    apps?.forEach(app => app?.roles?.map(({ allowedTenants, id }) => {
      if ((allowedTenants?.includes(ETenantType.SINGLE_COMPANY)) && !(allowedTenants?.includes(ETenantType.DIVISION_LIST))) {
        appRoleList.push(id);
      }
    }));

    return appRoleList;
  }

}
