import { FeatureKeys } from '@app/core/consts/app.const';
import { FilterSelectOptionsControlName, IconColor, ListManagementType, MiscIcon } from '@app/core/consts/list-management.const';
import { CompanyUserListColumnWidth, CompanyUserListTable, UNASSIGNED_GROUP_USER, UserStatus, UserStatusIcon } from '@app/core/consts/user.const';
import { ListManagement } from '@app/core/models/list-management';
import { ZonarUITableCellType as cellType } from '@zonar-ui/table';
import { Translations } from "@app/core/services/i18n/translations.service";
import { FeatureToggleService } from '@app/shared/services/feature-toggle.service';
import { toTitleCase } from '@app/core/utils';
import { uniqBy } from 'lodash';
import { IGroup } from '@app/core/models/group.model';
import { UserGroupPolicyCombine } from '@app/core/models/policy.model';

export function getUserListIconData(translations: Translations, translated) {
  return {
    [UserStatusIcon.INFO]: translated[translations.companyView.accountList.tableCellManagedUser].toUpperCase(),
    [UserStatusIcon.ACTIVE]: translated[translations.companyView.userList.tableStatusTooltip.active],
    [UserStatusIcon.INACTIVE]: translated[translations.companyView.userList.tableStatusTooltip.inactive]
  };
}

export const UserListIconColor = {
  [UserStatusIcon.ACTIVE]: IconColor.ACTIVE,
  [UserStatusIcon.INACTIVE]: IconColor.INACTIVE,
  [MiscIcon.EDIT]: IconColor.EDIT,
  [UserStatusIcon.INFO]: IconColor.INFO
};

export function getUserListCompanyColumnStyle(featureToggleService: FeatureToggleService) {
  return {
    [CompanyUserListTable.CHECK_BOX]: { 'max-width': CompanyUserListColumnWidth.CHECK_BOX },
    [CompanyUserListTable.STATUS]: featureToggleService.isFeatureEnabled(FeatureKeys.USER.BULK_EDIT) ?
      { 'max-width': CompanyUserListColumnWidth.STATUS_W_CHECK_BOX } :
      { 'max-width': CompanyUserListColumnWidth.STATUS },
    [CompanyUserListTable.NAME]: { 'max-width': CompanyUserListColumnWidth.NAME },
    [CompanyUserListTable.GROUP_POLICY]: featureToggleService.isFeatureEnabled(FeatureKeys.USER.EDIT) ?
      { 'max-width': CompanyUserListColumnWidth.GROUP_POLICY_W_EDIT } :
      { 'max-width': CompanyUserListColumnWidth.GROUP_POLICY },
    [CompanyUserListTable.EDIT]: { 'max-width': CompanyUserListColumnWidth.EDIT, 'justify-content': 'right' }
  };
}

export function getUserListColumns(companyId: string, translations: Translations, translated, featureToggleService: FeatureToggleService) {
  return [
    {
      columnDef: 'status', header: '',
      type: cellType.Icon,
      sortable: false, cell: (row) => {
        /* status field uses the status of company user directory */
        return row.user.status === UserStatus.ACTIVE ? 'fiber_manual_record' : 'stop';
      }
    },
    {
      columnDef: 'name', header: translated[translations.companyView.userList.tableHeaderName],
      sortable: true, cell: (row: any) => {
        return `${row.user.firstName ?? ''} ${row.user.middleName ?? ''} ${row.user.lastName ?? ''}`;
      },
    },
    {
      columnDef: 'groupPolicy', header: translated[translations.companyView.userList.tableHeaderGroupPolicy].toUpperCase(),
      type: cellType.Portal,
    },
    {
      columnDef: 'edit', header: '',
      type: cellType.Link, iLink: (row) => `//company/${companyId}/user/${row.user.id}/edit`,
      sortable: false, cell: (row) => translated[translations.companyView.userList.tableEditLink],
      hidden: !featureToggleService.isFeatureEnabled(FeatureKeys.USER.EDIT)
    }
  ];
}

export const UserListSkeletonLoader = { skeletonLoader: {} };

export function getUserListSearchActionView(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Search,
    searchManagement: {
      controlName: 'name',
      searchQuery: ['name'],
      searchPlaceHolder: translated[translations.companyView.userList.searchPlaceholder],
    },
  };
}

export function getUserListFilterStatusActionView(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Filter,
    filterManagement: {
      options: [
        {
          title: translated[translations.companyView.userList.status.active],
          value: UserStatus.ACTIVE
        },
        {
          title: translated[translations.companyView.userList.status.inActive],
          value: UserStatus.INACTIVE
        }
      ],
      filterLabel: translated[translations.companyView.userList.filterLabel.status],
      isMultiple: true,
      selectOptionsControlName: FilterSelectOptionsControlName.USERS_GROUP.STATUS
    }
  };
}

export function getUserListFilterGroup(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Filter,
    filterManagement: {
      options: [],
      filterLabel: translated[translations.companyView.userList.filterLabel.group],
      isMultiple: true,
      sortByStringTitle: false,
      selectOptionsControlName: FilterSelectOptionsControlName.USERS_GROUP.GROUPS
    }
  };
}

export function getUserListFilterGroupOptions(users, translations: Translations, translated) {
  if (!users || !users.length) {
    return [{
      title: translated[translations.companyView.userList.unassignedGroupUser], value: UNASSIGNED_GROUP_USER
    }];
  }

  let uniqueGroup = [];
  users.forEach(user => {
    if (user?.groups) {
      uniqueGroup.push(...user.groups);
    }
  });
  uniqueGroup = uniqBy(uniqueGroup, 'id').map(({ policy, tenant, id }) => ({ title: `${policy} - ${tenant}`, value: id }));
  uniqueGroup.unshift({
    title: translated[translations.companyView.userList.unassignedGroupUser], value: UNASSIGNED_GROUP_USER
  });

  return uniqueGroup;
}

export function getUserListCreateUserButtonManagement(companyId: string, menuList, translations: Translations, translated, featureToggleService: FeatureToggleService) {
  return {
    locateNavigate: `company/${companyId}/create-user`,
    buttonName: featureToggleService.isFeatureEnabled(FeatureKeys.USER.CREATE) ? translated[translations.companyView.userList.newUserButton] : null,
    menuName: translated[translations.companyView.bulkMenu.menuName],
    hasMenu: menuList.length ? true : false,
    menuList: menuList
  };
}

export const UserListCreateUserButton: ListManagement = {
  type: ListManagementType.Button,
  buttonManagement: {
    isDisabledButton: false,
    isOpenDialog: false
  }
};

export function getDisplayUserGroupPolicy(data: any, translations: Translations, translated, companyId: string) {
  let combineGroups = [];
  data?.groups?.forEach(group => {
    combineGroups.push(`${group.policy} - ${group.tenant}`);
  });
  return {
    title: `${data?.user?.firstName ?? ''} ${data?.user?.middleName ?? ''} ${data?.user?.lastName ?? ''}`,
    group: data?.groups?.length ? `${data.groups[0].policy} - ${data.groups[0].tenant}` : translated[translations.companyView.userList.unassignedGroupUser],
    groupTooltipText: combineGroups.length > 1 ? `+${combineGroups.length - 1} ${translated[translations.companyView.userList.tableCardMore]}` : '',
    groupTooltip: combineGroups.length > 1 ? combineGroups.join('\n') : '',
    /* status field uses the status of company user directory */
    status: `${translated[translations.companyView.userList.tableCardStatus]}: ${toTitleCase(data?.user?.status)}`,
    editText: translated[translations.companyView.userList.tableCardEditButton],
    editLink: `//company/${companyId}/user/${data?.user?.userId}/edit`
  };
}

export function buildUserListGroups(users, groups?: Array<IGroup>, applications = []) {
  if (!users || !users.length) {
    return [];
  }
  
  const usersGroupPolicyCombine: UserGroupPolicyCombine[] = [];
  users.forEach(user => {
    const userCombine: UserGroupPolicyCombine = {
      user,
      groups: [],
      isEmptyRole: null
    };
    groups?.forEach(group => {
      const isEmptyRole = group.policy.grants.every(grant => !applications.find(app => app.id === grant.application.id));
      if (group.members.find(userGroup => userGroup.id === user?.userId)) {
        userCombine.groups.push({id: group.id, policy: group.policy?.name, tenant: group.tenant?.name, isEmptyRole});
      }
    });
    userCombine.isEmptyRole = userCombine.groups.length ? userCombine.groups.every(group => group.isEmptyRole) : false;
    usersGroupPolicyCombine.push(userCombine);
  });
  return usersGroupPolicyCombine;
}
