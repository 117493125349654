import { createReducer, on } from "@ngrx/store";
import { AssetPropertyStoreState } from "./asset-properties-data-store.interface";
import * as AssetPropertyStoreActions from './asset-properties-data-store.action';
import { CoreEntityStatus } from "@app/core/consts/app.const";
import { cloneDeep } from 'lodash';

export const featureKey = 'asset-property';

export const initialState: AssetPropertyStoreState = {
  assetCustomProperties: null,
  isAssetCustomPropertiesLoading: false,
  assetCustomPropertyCreated: null,
  isSavePropertyAttributeLoading: null,
  createPropertyValueProgress: null,
  updatePropertyValueProgress: null,
  deletePropertyValueProgress: null,
};

export const reducer = createReducer(
  initialState,

  // #region PROPERTY
  on(AssetPropertyStoreActions.getAssetCustomProperties, (state, action) => ({
    ...state,
    isAssetCustomPropertiesLoading: true
  })),

  on(AssetPropertyStoreActions.getAssetCustomPropertiesSuccess, (state, action) => {
    const results = [];
    action.payload.assetCustomProperties.forEach(asset => {
      if (asset.status === CoreEntityStatus.ACTIVE) {
        const newAsset = cloneDeep(asset);
        newAsset.values = asset.values.filter(value => value.status === CoreEntityStatus.ACTIVE);
        results.push(newAsset);
      }
    });
    return {
      ...state,
      assetCustomProperties: results,
      isAssetCustomPropertiesLoading: false
    };
  }),

  //TODO handle error
  on(AssetPropertyStoreActions.getAssetCustomPropertiesFailure, (state, action) => ({
    ...state,
    assetCustomProperties: [],
    isAssetCustomPropertiesLoading: false
  })),

  on(AssetPropertyStoreActions.createAssetProperty, (state, action) => {
    return {
      ...state,
      assetCustomPropertyCreated: null
    };
  }),

  on(AssetPropertyStoreActions.createAssetPropertySuccess, (state, action) => {
    return {
      ...state,
      assetCustomPropertyCreated: action.payload.assetCustomPropertyCreated
    };
  }),

  //TODO handle error
  on(AssetPropertyStoreActions.createAssetPropertyFailure, (state, action) => {
    return {
      ...state,
    };
  }),

  on(AssetPropertyStoreActions.updateAssetProperty, (state, action) => {
    return {
      ...state,
      isSavePropertyAttributeLoading: true,
    };
  }),

  on(AssetPropertyStoreActions.updateAssetPropertySuccess, (state, action) => {
    return {
      ...state,
      isSavePropertyAttributeLoading: false,
    };
  }),

  // TODO handle error
  on(AssetPropertyStoreActions.updateAssetPropertyFailure, (state, action) => {
    return {
      ...state,
      isSavePropertyAttributeLoading: false,
    };
  }),
  // #endregion PROPERTY

  // #region PROPERTY VALUE
  on(AssetPropertyStoreActions.createPropertyValue, (state, action) => {
    return {
      ...state,
    };
  }),

  on(AssetPropertyStoreActions.createPropertyValueSuccess, (state, action) => {
    let count = state.createPropertyValueProgress || 0;
    count++;
    return {
      ...state,
      createPropertyValueProgress: count,
    };
  }),

  // TODO handle error
  on(AssetPropertyStoreActions.createPropertyValueFailure, (state, action) => {
    let count = state.createPropertyValueProgress || 0;
    count++;
    return {
      ...state,
      createPropertyValueProgress: count,
    };
  }),

  on(AssetPropertyStoreActions.updatePropertyValue, (state, action) => {
    return {
      ...state,
    };
  }),

  on(AssetPropertyStoreActions.updatePropertyValueSuccess, (state, action) => {
    let count = state.updatePropertyValueProgress || 0;
    count++;
    return {
      ...state,
      updatePropertyValueProgress: count,
    };
  }),

  // TODO handle error
  on(AssetPropertyStoreActions.updatePropertyValueFailure, (state, action) => {
    let count = state.updatePropertyValueProgress || 0;
    count++;
    return {
      ...state,
      updatePropertyValueProgress: count,
    };
  }),

  on(AssetPropertyStoreActions.inactivePropertyValue, (state, action) => {
    return {
      ...state,
    };
  }),

  on(AssetPropertyStoreActions.inactivePropertyValueSuccess, (state, action) => {
    let count = state.deletePropertyValueProgress || 0;
    count++;
    return {
      ...state,
      deletePropertyValueProgress: count,
    };
  }),

  // TODO handle error
  on(AssetPropertyStoreActions.inactivePropertyValueFailure, (state, action) => {
    let count = state.deletePropertyValueProgress || 0;
    count++;
    return {
      ...state,
      deletePropertyValueProgress: count,
    };
  }),
  // #endregion PROPERTY VALUE

  // #region RESET
  on(AssetPropertyStoreActions.resetAssetCustomPropertyProgress, (state, action) => {
    return {
      ...state,
      assetCustomPropertyCreated: null,
      createPropertyValueProgress: null,
      updatePropertyValueProgress: null,
      deletePropertyValueProgress: null,
      isSavePropertyAttributeLoading: null,
    };
  }),
  // #endregion RESET
);
