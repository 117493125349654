import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { FlexModule } from '@angular/flex-layout';
import { SettingToggleComponent } from './components/setting-toggle/setting-toggle.component';
import { TranslateModule, ZonarUiI18nModule } from '@zonar-ui/i18n';
import { CautionPopupComponent } from './dialogs/caution-popup/caution-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZonarUITableModule } from '@zonar-ui/table';
import { ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { CoreModule } from '@app/core/core.module';
import { ListManagementComponent } from './components/list-management/list-management.component';
import { RouterModule } from '@angular/router';
import { ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';
import { SearchComponentModule } from './components/search/search.component.module';
import { RoleCapabilitiesComponent } from './components/role-capabilities/role-capabilities.component';
import { DivisionDialogComponent } from './dialogs/division-dialog/division-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ThrottledClickDirective } from './directives/throttled-click.directive';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { PreviewDialogComponent } from './dialogs/preview-dialog/preview-dialog.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { WarningComponent } from './components/warning/warning.component';
import { AnnouncementDialogComponent } from './dialogs/announcement-dialog/announcement-dialog.component';
import { GeneratePasswordDialogComponent } from './dialogs/generate-password-dialog/generate-password-dialog.component';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { BulkAssetImportComponent } from './dialogs/bulk-asset-import/bulk-asset-import.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { BulkEditResultComponent } from './dialogs/bulk-edit-result/bulk-edit-result.component';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { ImportGuideComponent } from './components/import-guide/import-guide.component';
import { SettingPanelComponent } from './components/setting-panel/setting-panel.component';
import { NestedDivisionComponent } from './components/nested-division/nested-division.component';
import { TreeWithCheckboxesComponent } from './components/tree-with-checkboxes/tree-with-checkboxes.component';
import { MatTreeModule } from '@angular/material/tree';
import { TreeBaseComponent } from './components/tree-base/tree-base.component';
import { BulkUserImportComponent } from './dialogs/bulk-user-import/bulk-user-import.component';
import { MultiStatesToggleComponent } from './components/multi-states-toggle/multi-states-toggle.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { ZonarUIThreeStatesToggleModule } from '@zonar-ui/three-states-toggle';
import { BulkUserPreviewDialogComponent } from './dialogs/bulk-user-preview-dialog/bulk-user-preview-dialog.component';
import { BulkUserImportRequirementsComponent } from './components/bulk-user-import-requirements/bulk-user-import-requirements.component';
import { BulkAssetImportRequirementsComponent } from './components/bulk-asset-import-requirements/bulk-asset-import-requirements.component';
import { MultiDropdownPanelComponent } from './components/multi-dropdown-panel/multi-dropdown-panel.component';
import { CreateEditWithTreeDialogComponent } from './dialogs/create-edit-with-tree-dialog/create-edit-with-tree-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreateEditWithTreeComponent } from './components/create-edit-with-tree/create-edit-with-tree.component';
import { CreateEditWithTreePageComponent } from './components/create-edit-with-tree-page/create-edit-with-tree-page.component';
import { CardListComponent } from '@app/shared/components/card-list/card-list.component';
import { MatCardModule } from '@angular/material/card';
import { CardComponent } from './components/card/card.component';
import { MatDividerModule } from '@angular/material/divider';
import { SnackBannerComponent } from './components/snack-banner/snack-banner.component';
import { MigrationStartDialogComponent } from './dialogs/migration-start-dialog/migration-start-dialog.component';
import { MigrationIndeterminateDialogComponent } from '@app/shared/dialogs/migration-indeterminate-dialog/migration-indeterminate-dialog.component';
import { CreateEditCustomPropertyDialogComponent } from './dialogs/create-edit-custom-property-dialog/create-edit-custom-property-dialog.component';
import { SingleDropdownPanelComponent } from './components/single-dropdown-panel/single-dropdown-panel.component';
import { SingleGridPanelComponent } from './components/single-grid-panel/single-grid-panel.component';
import { ConfirmMessageDialogComponent } from './dialogs/confirm-message-dialog/confirm-message-dialog.component';
import { MobileDrawerComponent } from './components/mobile-drawer/mobile-drawer.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { InformationDialogComponent } from './dialogs/information-dialog/information-dialog.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmChangeDialogComponent } from './dialogs/confirm-change-dialog/confirm-change-dialog.component';
import { OverlaySpinnerComponent } from './components/overlay-spinner/overlay-spinner.component';
import { LabelComponent } from './components/label/label.component';

@NgModule({
  declarations: [
    SettingToggleComponent,
    CautionPopupComponent,
    ListManagementComponent,
    RoleCapabilitiesComponent,
    DivisionDialogComponent,
    ConfirmDialogComponent,
    ThrottledClickDirective,
    CheckboxListComponent,
    PreviewDialogComponent,
    PageNotFoundComponent,
    WarningComponent,
    AnnouncementDialogComponent,
    GeneratePasswordDialogComponent,
    AlertDialogComponent,
    BulkAssetImportComponent,
    FileUploadComponent,
    DragAndDropDirective,
    BulkEditResultComponent,
    ChipListComponent,
    ImportGuideComponent,
    SettingPanelComponent,
    MultiStatesToggleComponent,
    NestedDivisionComponent,
    TreeWithCheckboxesComponent,
    TreeBaseComponent,
    BulkUserImportComponent,
    BulkUserPreviewDialogComponent,
    BulkUserImportRequirementsComponent,
    BulkAssetImportRequirementsComponent,
    CreateEditWithTreeDialogComponent,
    CreateEditWithTreeComponent,
    CreateEditWithTreePageComponent,
    MultiDropdownPanelComponent,
    CardListComponent,
    CardComponent,
    MigrationIndeterminateDialogComponent,
    SnackBannerComponent,
    MigrationStartDialogComponent,
    CreateEditCustomPropertyDialogComponent,
    SingleDropdownPanelComponent,
    SingleGridPanelComponent,
    ConfirmMessageDialogComponent,
    MobileDrawerComponent,
    InformationDialogComponent,
    LoadingSpinnerComponent,
    ConfirmChangeDialogComponent,
    OverlaySpinnerComponent,
    LabelComponent,
  ],
  imports: [
    CommonModule,
    ZonarUIMaterialModule,
    FlexModule,
    ZonarUiI18nModule,
    FormsModule,
    ZonarUITableModule,
    ZonarUiPermissionsModule,
    ReactiveFormsModule,
    CoreModule,
    ZonarUiSearchableDropdownModule,
    RouterModule,
    SearchComponentModule,
    ZonarUIMaterialModule,
    MatTreeModule,
    MatExpansionModule,
    MatRadioModule,
    ZonarUIThreeStatesToggleModule,
    TranslateModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    SettingToggleComponent,
    ListManagementComponent,
    RoleCapabilitiesComponent,
    ThrottledClickDirective,
    CheckboxListComponent,
    PreviewDialogComponent,
    PageNotFoundComponent,
    WarningComponent,
    AnnouncementDialogComponent,
    GeneratePasswordDialogComponent,
    BulkAssetImportComponent,
    DragAndDropDirective,
    ChipListComponent,
    ImportGuideComponent,
    SettingPanelComponent,
    MultiStatesToggleComponent,
    TreeWithCheckboxesComponent,
    BulkUserPreviewDialogComponent,
    BulkUserImportRequirementsComponent,
    BulkAssetImportRequirementsComponent,
    MultiDropdownPanelComponent,
    CardListComponent,
    CardComponent,
    CreateEditWithTreeComponent,
    MigrationIndeterminateDialogComponent,
    SnackBannerComponent,
    SingleDropdownPanelComponent,
    SingleGridPanelComponent,
    MobileDrawerComponent,
    InformationDialogComponent,
    LoadingSpinnerComponent,
    ConfirmChangeDialogComponent,
    LabelComponent,
  ]
})
export class SharedModule { }
