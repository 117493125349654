import { Injectable } from '@angular/core';
import { I18nService } from '@zonar-ui/i18n';
import { environment } from '@environments/environment';
import { EntityType } from '@app/core/consts/setting.const';
@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(
    private i18nService: I18nService
  ) { }

  getFilePathDownloadAsset() {
    let downloadPath = `${environment.region.toUpperCase()}/example-`;

    downloadPath = `assets/bulk-import-sample-files/asset/${downloadPath}${this.i18nService.getLanguageInUse()?.toUpperCase()}.csv`;

    return downloadPath;
  }

  getFilePathDownloadUser(isManaged = false) {
    let downloadPath = '';

    if (isManaged) {
      downloadPath = `managed/${environment.region.toUpperCase()}/example-`;
    } else {
      downloadPath = `standard/${environment.region.toUpperCase()}/example-`;
    }

    downloadPath = `assets/bulk-import-sample-files/user/${downloadPath}${this.i18nService.getLanguageInUse()?.toUpperCase()}.csv`;

    return downloadPath;
  }

  // TODO: Need to up date with actual URLs
  getEnvironmentBasedUrl(entityType: EntityType) {
    //TODO: check region.
    switch (entityType) {
      case EntityType.COMPANY:
        return ``;

      case EntityType.DIVISION:
        return ``;

      case EntityType.ASSET:
        return ``;

      case EntityType.USER:
        return ``;

      case EntityType.GTCSELECTACCOUNT:
        return environment.gtcUserManagement.selectAccountLink;
      
      case EntityType.GTCUSERMANAGEMENT:
        return environment.gtcUserManagement.userManagementLink;

      case EntityType.GTCASSETMANAGEMENT:
        return environment.gtcAssetManagement.assetManagementLink;
      
      default:
        return ``;
    }
  }

}
