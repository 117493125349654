/* angular */
import { IUser, IUserNavigation, IUserPolicy, UserProfile } from '@app/core/models/user.model';
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as UsersDataStoreActions from './users-data-store.actions';
import { BulkUsersImportStatuses, EditBulkUsers, ImportBulkUsers } from './users-data-store.interface';

/* state key */
export const featureKey = 'users';

/* state interface */

export interface UsersDataStore {
  isLoading?: boolean;
  user: IUser,
  users?: IUser[];
  activeUser?: IUser;
  importUsers?: ImportBulkUsers;
  editUsers?: EditBulkUsers;
  bulkDeactivateUser?: EditBulkUsers;
  userProfileList?: UserProfile[];
  combineUserList?: any[];
  userPolicies?: IUserPolicy[];
  usersGroupPolicy?: any[];
  userInfoNavigate?: IUserNavigation;
}


/* initial state */
export const initialState: UsersDataStore = {
  isLoading: null,
  user: null,
  users: [],
  activeUser: null,
  importUsers: null,
  editUsers: null,
  userProfileList: [],
  combineUserList: [],
  userPolicies: null,
  userInfoNavigate: null,
};

export const reducer = createReducer(
  initialState,
  on(UsersDataStoreActions.bulkUsersCSVDataFileSelected, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      status: BulkUsersImportStatuses.VALIDATING_FILE,
      company: {
        id: action.payload.companyId,
        divisions: action.payload.divisions
      },
      uploadedData: {
        ...state.importUsers?.uploadedData,
        csvFile: {
          name: action.payload.csvFile?.name,
          size: action.payload.csvFile?.size,
          type: action.payload.csvFile?.type
        }
      },
      importedData: {
        ...state.importUsers?.importedData,
        processedRowsCount: 0,
        successfullyImportedUsers: [],
        erroredUsers: []
      }
    }
  })),

  on(UsersDataStoreActions.bulkUsersCSVDataValidated, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      status: BulkUsersImportStatuses.PARSING_DATA,
      uploadedData: {
        ...state.importUsers?.uploadedData,
        recordRows: action.payload.recordRows
      }
    }
  })),

  on(UsersDataStoreActions.bulkUsersCSVParsingStarted, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      status: BulkUsersImportStatuses.PARSING_DATA
    }
  })),

  on(UsersDataStoreActions.bulkUserCSVFileForInvalidDataGenerated, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      status: BulkUsersImportStatuses.DATA_LOADED,
      uploadedData: {
        ...state.importUsers?.uploadedData,
        invalidDataCsvFile: action.payload.csvFile
      }
    }
  })),

  on(UsersDataStoreActions.importValidatedBulkUsersData, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      status: BulkUsersImportStatuses.IMPORTING_USERS,
    }
  })),

  on(UsersDataStoreActions.bulkUsersCSVRecordParsed, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      uploadedData: {
        ...state.importUsers?.uploadedData,
        recordHashmap: action.payload.recordHashmap,
        headerArray: action.payload.headers
      }
    }
  })),
  on(UsersDataStoreActions.importUserSuccessful, (state, action) => {
    let count = state.importUsers.importedData.processedRowsCount;
    count++;
    return {
      ...state,
      importUsers: {
        ...state.importUsers,
        importedData: {
          ...state.importUsers?.importedData,
          processedRowsCount: count,
          successfullyImportedUsers: [...state.importUsers.importedData.successfullyImportedUsers, { id: action.payload.userId }]
        },
      }
    };
  }),

  on(UsersDataStoreActions.importUserFailure, (state, action) => {
    return {
      ...state,
      importUsers: {
        ...state.importUsers,
        importedData: {
          ...state.importUsers?.importedData
        }
      }
    };
  }),
  on(UsersDataStoreActions.importUser, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      status: BulkUsersImportStatuses.IMPORTING_USERS
    }
  })),
  on(UsersDataStoreActions.bulkUsersImportCompleted, (state, action) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      status: BulkUsersImportStatuses.IMPORTING_COMPLETED
    }
  })),
  on(UsersDataStoreActions.bulkUsersImportResetUploadedData, (state, action) => ({
    ...state,
    importUsers: null
  })),
  on(UsersDataStoreActions.saveError, (state, action) => {
    let count = state.importUsers.importedData.processedRowsCount;
    count++;
    let recordError = state.importUsers.uploadedData.recordHashmap[action.payload.index + 1];
    if (recordError) {
      const lackingColumnCount = state.importUsers.uploadedData.headerArray.length - recordError.length;
      if (lackingColumnCount > 0) {
        for (let i = 0; i < lackingColumnCount; i++) {
          recordError = [...recordError, ''];
        }
      }
      recordError = [...recordError, action.payload.message];
    }
    return {
      ...state,
      importUsers: {
        ...state.importUsers,
        importedData: {
          ...state.importUsers.importedData,
          processedRowsCount: count,
          erroredUsers: recordError ?
            [...state.importUsers.importedData.erroredUsers, recordError] : [...state.importUsers.importedData.erroredUsers]
        }
      }
    };
  }),

  on(UsersDataStoreActions.resetUserState, (state, action) => (initialState)),

  on(UsersDataStoreActions.bulkUserEditSelected, (state, action) => {
    return {
      ...state,
      editUsers: {
        ...state.editUsers,
        processedRowsCount: 0,
        selectedUsers: action.payload.selectedUsers,
        successfullyEditedUsers: [],
        errorEditedUsers: []
      }
    };
  }),

  on(UsersDataStoreActions.bulkDeactivateUsers, (state, action) => {
    return {
      ...state,
      bulkDeactivateUser: {
        ...state.bulkDeactivateUser,
        processedRowsCount: 0,
        selectedUsers: [],
        successfullyEditedUsers: [],
        errorEditedUsers: []
      }
    };
  }),

  on(UsersDataStoreActions.bulkUserEditSuccessResult, (state, action) => {
    let count = state.editUsers.processedRowsCount;
    count++;
    return {
      ...state,
      editUsers: {
        ...state.editUsers,
        processedRowsCount: count,
        successfullyEditedUsers: [...state.editUsers.successfullyEditedUsers, action.payload.user]
      }
    };
  }),
  on(UsersDataStoreActions.bulkUserEditErrorResult, (state, action) => {
    let count = state.editUsers.processedRowsCount;
    count++;
    return {
      ...state,
      editUsers: {
        ...state.editUsers,
        processedRowsCount: count,
        errorEditedUsers: [...state.editUsers.errorEditedUsers, action.payload.user]
      }
    };
  }),

  on(UsersDataStoreActions.bulkDeactivateUserProfileSuccess, (state, action) => {
    let count = state.bulkDeactivateUser.processedRowsCount;
    count++;
    return {
      ...state,
      bulkDeactivateUser: {
        ...state.bulkDeactivateUser,
        processedRowsCount: count,
        successfullyEditedUsers: [...state.bulkDeactivateUser.successfullyEditedUsers, action.payload.user]
      }
    };
  }),

  on(UsersDataStoreActions.bulkDeactivateUserProfileError, (state, action) => {
    let count = state.bulkDeactivateUser.processedRowsCount;
    count++;
    return {
      ...state,
      bulkDeactivateUser: {
        ...state.bulkDeactivateUser,
        processedRowsCount: count,
        errorEditedUsers: [...state.bulkDeactivateUser.errorEditedUsers, action.payload.user]
      }
    };
  }),

  on(UsersDataStoreActions.getAllUsersSuccessful, (state, action) => {
    return {
      ...state,
      users: action.payload.userList
    };
  }),

  // TODO handle error
  on(UsersDataStoreActions.getAllUsersFailure, (state, action) => {
    return {
      ...state,
    };
  }),

  on(UsersDataStoreActions.getAllUsersProfiles, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(UsersDataStoreActions.getAllUsersProfilesSuccessful, (state, action) => {
    return {
      ...state,
      isLoading: false,
      userProfileList: action.payload.userProfileList
    };
  }),

  // TODO handle error
  on(UsersDataStoreActions.getAllUsersProfilesFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  }),

  on(UsersDataStoreActions.setCombineUserList, (state, action) => {
    return {
      ...state,
      combineUserList: action.payload.combineUserList
    };
  }),

  on(UsersDataStoreActions.getUserPolicies, (state, action) => {
    return {
      ...state,
      userPolicies: null
    };
  }),

  on(UsersDataStoreActions.getUserPoliciesSuccess, (state, action) => {
    return {
      ...state,
      userPolicies: action.payload.userPolicies
    };
  }),

  on(UsersDataStoreActions.bulkUserEditReset, (state, action) => {
    return {
      ...state,
      editUsers: {
        ...state.editUsers,
        processedRowsCount: 0,
        errorEditedUsers: [],
        successfullyEditedUsers: []
      },
      bulkDeactivateUser: {
        ...state.bulkDeactivateUser,
        processedRowsCount: 0,
        errorEditedUsers: [],
        successfullyEditedUsers: []
      }
    };
  }),

  on(UsersDataStoreActions.getUsersByCompanyId, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  }),

  on(UsersDataStoreActions.getUsersByCompanyIdSuccessful, (state, action) => {
    return {
      ...state,
      isLoading: false,
      usersGroupPolicy: action.payload.userList
    };
  }),

  on(UsersDataStoreActions.getUsersByCompanyIdFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  }),

  on(UsersDataStoreActions.setUser, (state, action) => {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user
    };
  }),

  on(UsersDataStoreActions.setUserNavigation, (state, action) => {
    return {
      ...state,
      userInfoNavigate: action.payload.userInfo
    };
  }),
  //#region Common
  on(UsersDataStoreActions.getUserById, (state) => {
    return {
      ...state,
      user: null,
    };
  }),
  on(UsersDataStoreActions.getUserByIdSuccess, (state, action) => {
    return {
      ...state,
      user: action.payload.user,
    };
  }),
  //#endregion

  on(UsersDataStoreActions.resetUserPolicy, (state, action) => ({
    ...state,
    userPolicies: null
  })),
);


