import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ICreateEditViewTree } from '@app/core/models/policy.model';
import { ComponentViewMode, Feature, MOBILE_BREAK_POINT } from '@app/core/consts/app.const';
import { sortBy } from '@app/core/utils';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TreeWithCheckboxesComponent } from '../tree-with-checkboxes/tree-with-checkboxes.component';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { PermissionsService } from '@zonar-ui/auth';
import { Permissions } from '@app/core/consts/permission.const';

@Component({
  selector: 'app-create-edit-with-tree',
  templateUrl: './create-edit-with-tree.component.html',
  styleUrls: ['./create-edit-with-tree.component.scss']
})
export class CreateEditWithTreeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: ICreateEditViewTree;
  @Output() confirmEmitter = new EventEmitter<any>();
  @ViewChild('viewChild') viewChild!: TreeWithCheckboxesComponent;

  disabledTree = false;
  allItemsChecked = false;
  persistTooltip = false;
  hasTreeItemsChanged = false;
  itemsSelectedOnView = [];
  searchValue = null;
  initListName = '';
  searchControl = new FormControl('');
  listName = new FormControl('', Validators.required);
  translationsNotLoaded = !!this.translateService.store.translations;
  private onDestroy$ = new Subject<void>();
  itemDuplicated = {};
  documentComponent = document;
  disableSubItemSelection = true;
  itemSelectedDataSource = [];
  hasCreateTenantPerm: boolean;
  hasCreatePolicyPerm: boolean;
  hasUpdateTenantPerm: boolean;
  hasUpdatePolicyPerm: boolean;
  allRoleCompanyLevelCapaList = [];
  companyLevelCapaSelectedList = [];
  warningData: any = {};
  imageSrc = 'assets/icons/Severity-icons.svg';

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private dialogRef: MatDialogRef<CreateEditWithTreeComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    private i18nService: I18nService,
    public store: Store<any>,
    public regionParserService: RegionParserService,
    private changeDetector: ChangeDetectorRef,
    private permissionService: PermissionsService,
  ) {
    if (this.translationsNotLoaded) {
      this.translateService.use(this.i18nService.getLanguageInUse())
        .pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.allRoleCompanyLevelCapaList = this.data.allRoleCompanyLevelCapaList;
      this.companyLevelCapaSelectedList = this.data.companyLevelCapaSelectedList;
      if (this.data.feature === Feature.POLICY) {
        this.buildWarningData();
      }
      this.disableSubItemSelection = this.data.disableSubItemSelection ?? true;
      this.initComponent();
      this.changeDetector.detectChanges();
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.searchValue = value;
    });

    this.initComponent();
  }

  buildWarningData() {
    if (this.companyLevelCapaSelectedList === null) {
      this.warningData = {
        image: this.imageSrc,
        title: this.data.translated?.warningData?.restrictedRoleText?.title,
        message: this.data.translated?.warningData?.restrictedRoleText?.message,
      };
    } else if (!this.companyLevelCapaSelectedList.length) {
      this.warningData = {
        image: this.imageSrc,
        title: this.data.translated?.warningData?.accessChangeText?.title,
        message: this.data.translated?.warningData?.accessChangeText?.message,
      };
    } else {
      this.warningData = null;
    }

  }

  initComponent() {
    if (this.data?.componentMode && this.data.componentMode !== ComponentViewMode.CREATE) {
      this.allItemsChecked = this.data.allItemsChecked;
      this.listName.setValue(this.data.initInfo?.name);
      this.initListName = this.data.initInfo?.name;
      this.itemsSelectedOnView = cloneDeep(this.data.initInfo?.initItemSelected);
    }

    if (this.data?.componentMode && this.data.componentMode === ComponentViewMode.VIEW) {
      if (this.data.feature === Feature.TENANT) {
        this.permissionService.hasPermission(Permissions.CREATE_TENANT).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasCreateTenantPerm = hasPerm;
        });
        this.permissionService.hasPermission(Permissions.UPDATE_TENANT).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasUpdateTenantPerm = hasPerm;
        });
      } else {
        this.permissionService.hasPermission(Permissions.CREATE_POLICY).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasCreatePolicyPerm = hasPerm;
        });
        this.permissionService.hasPermission(Permissions.UPDATE_POLICY).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasUpdatePolicyPerm = hasPerm;
        });
      }
      this.disabledTree = true;
      this.listName.disable();
    }
  }

  getContentMargin() {
    let element: any = this.documentComponent.querySelector('.create-edit-tree-content');
    let elementTitle: any = this.documentComponent.querySelector('.title');

    if (element?.scrollWidth < MOBILE_BREAK_POINT) {
      element.style.cssText = 'display: flex; flex-direction: column';
      elementTitle.style.cssText = 'width: 100%';
    } else {
      element.style.cssText = 'display: flex; flex-direction: row';
      elementTitle.style.cssText = 'width: 50%';
    }
    return '';
  }

  checkDataChanged() {
    if (this.data?.componentMode === ComponentViewMode.CREATE) {
      return this.hasNameChanged() && this.hasTreeChanged();
    }

    return this.hasNameChanged() || this.hasTreeChanged();
  }

  hasNameChanged() {
    return this.listName.value?.trim() !== this.initListName?.trim();
  }

  public get ComponentMode(): typeof ComponentViewMode {
    return ComponentViewMode;
  }

  resetSelectedData() {
    this.data.dataList.forEach(item => {
      item.selected = false;
      item.disabled = false;

      if (item.children?.length) {
        item.children.forEach(child => {
          if (child && typeof child === 'object') {
            child.selected = false;
            child.disabled = false;
          }
        });
      }
    });
    this.data.dataList = [...this.data.dataList];
  }

  onCreate() {
    if (this.data?.isDialog) {
      this.data.componentMode = ComponentViewMode.CREATE;
      this.resetSelectedData();
      this.listName.enable();
      this.listName.setValue('');
      this.searchControl.setValue('');
      this.itemsSelectedOnView = [];
      this.data.initInfo.initItemSelected = [];

      this.allItemsChecked = false;
      this.disabledTree = false;
      this.initListName = '';
      this.persistTooltip = false;
    } else {
      if (this.data?.pageNavigateLink?.create) {
        this.router.navigateByUrl(this.data.pageNavigateLink.create);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  onEdit() {
    if (this.data?.isDialog) {
      this.data.componentMode = ComponentViewMode.EDIT;
      this.listName.enable();
      this.searchControl.setValue('');
      this.disabledTree = false;
      this.disableSubItemSelection = this.data?.disableSubItemSelection;
    } else {
      if (this.data?.pageNavigateLink?.edit) {
        this.router.navigateByUrl(this.data.pageNavigateLink.edit);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  onCancel() {
    if (this.data?.isDialog) {
      this.matDialog.closeAll();
    } else {
      this.onNavigateBack();
    }
  }

  hasDuplicatedTree(): boolean {
    if (!this.hasTreeChanged()) {
      return;
    }

    let itemsSelected = cloneDeep(this.itemsSelectedOnView);

    let result = false;
    this.itemDuplicated = {};
    itemsSelected = itemsSelected.slice().sort();

    for (let item of this.data?.dataValidation) {
      if (JSON.stringify(item?.validationList.slice().sort()) === JSON.stringify(itemsSelected)) {
        result = true;
        this.itemDuplicated = item;
      }
    };

    return result;
  }

  hasDuplicatedName() {
    if (this.hasNameChanged()) {
      return this.data.dataValidation.find(item => item.name.toLowerCase() === this.listName.value.toLowerCase());
    }
  }

  onConfirm() {
    if (this.hasDuplicatedName()) {
      this.listName.markAsTouched();
      this.listName.setErrors({ 'duplicated-name': true });

      return;
    }

    if (this.data.feature === Feature.TENANT) {
      if (this.hasDuplicatedTree()) {
        this.listName.markAsTouched();
        this.listName.setErrors({ 'duplicated-tree': true });

        return;
      }
    }

    const result = {
      data: this.data,
      dataChanged: this.hasTreeItemsChanged ? this.itemsSelectedOnView : [],
      name: this.listName.value,
      allItemsChecked: this.allItemsChecked
    };
    if (this.data.isDialog) {
      this.dialogRef.close(result);
    } else {
      this.confirmEmitter.emit(result);
    }
  }

  onNavigateBack() {
    this.router.navigateByUrl(this.data?.pageNavigateLink?.navigateBack ? this.data.pageNavigateLink.navigateBack : `/demo-page`);
  }

  resetFormName() {
    this.listName.setErrors({ duplicated: null });
    this.listName.updateValueAndValidity();
  }

  buildItemSelectedOnView() {
    this.itemSelectedDataSource = [];
    this.itemSelectedDataSource = this.buildItemSelectedDataSource(this.viewChild.tmpDataSource);

    this.itemsSelectedOnView = this.itemSelectedDataSource.map(item => item.id);
    if (this.data.componentMode === ComponentViewMode.EDIT) {
      this.hasTreeItemsChanged = JSON.stringify(this.itemsSelectedOnView.sort()) !== JSON.stringify(this.data.initInfo.initItemSelected.sort());
    } else {
      this.hasTreeItemsChanged = !!this.itemSelectedDataSource.length;
    }
  }

  selectOrDeselectAll(value) {
    this.viewChild.selectOrDeselectedItems(value);
    this.selectItem({ addComplete: value });
  }

  buildItemSelectedDataSource(array) {
    array.forEach(item => {
      if (item.selected && !item.disabled) {
        this.itemSelectedDataSource.push(item);
      }

      if (item.children.length) {
        this.buildItemSelectedDataSource(item.children);
      }
    });
    return this.itemSelectedDataSource;
  }

  selectItem(event) {
    this.resetFormName();
    this.allItemsChecked = event.addComplete;
    this.buildItemSelectedOnView();
    if (this.data?.companyLevelCapaSelectedList?.length) {
      this.isShowAlertMessage(event.data);
    }
  }

  isShowAlertMessage(data) {
    let temp = cloneDeep(this.companyLevelCapaSelectedList);
    if (data.parentId) {
      data.descendants = [data];
    }

    if (data.selected) {
      data.descendants.forEach(des => {
        if (this.data.allRoleCompanyLevelCapaList.includes(des.id)) {
          temp.push(des.id);
        }
      });
    } else {
      data.descendants.forEach(des => {
        if (temp.includes(des.id)) {
          temp = temp.filter(item => item !== des?.id);
        }
      });
    }

    this.companyLevelCapaSelectedList = temp;
    this.buildWarningData();
  }

  hasTreeChanged(): boolean {
    return !!(this.hasTreeItemsChanged && this.itemsSelectedOnView.length);
  }

  onUserAffectTooltipMouseEnter(event: MouseEvent, userAffectTooltip: MatTooltip) {
    event.stopImmediatePropagation();
    if (this.persistTooltip) {
      // persist state
      if (!userAffectTooltip._isTooltipVisible()) {
        userAffectTooltip.show();
        this.persistTooltip = false;
      }
    } else {
      // idle state
      userAffectTooltip.show();
    }
  }


  onUserAffectTooltipMouseLeave(event: MouseEvent, userAffectTooltip: MatTooltip) {
    event.stopImmediatePropagation();
    if (this.persistTooltip) {
      // persist state

    } else {
      // idle state
      userAffectTooltip.hide();
    }
  }

  onUserAffectTooltipClick(event: MouseEvent, userAffectTooltip: MatTooltip) {
    if (this.persistTooltip) {
      // persist state
      this.persistTooltip = false;
      userAffectTooltip.hide();
    } else {
      // idle state
      userAffectTooltip.show();
      this.persistTooltip = true;
    }
  }

  onUserAffectTooltipClose(event: MouseEvent, userAffectTooltip: MatTooltip) {
    event.stopImmediatePropagation();
    userAffectTooltip.show();
  }

  getUserAffectTooltipMessage() {
    if (!this.data?.initInfo?.members) {
      return '';
    }
    return sortBy(this.data.initInfo.members).join('\n');
  }

  getTitle() {
    switch (this.data?.componentMode) {
      case ComponentViewMode.CREATE:
        return this.data.translated?.createTitle;
      case ComponentViewMode.EDIT:
        return this.data.translated?.editTitle;
      case ComponentViewMode.VIEW:
        return this.data.translated?.viewTitle;
      default:
        return '';
    }
  }

  getParsedKeyWords() {
    return this.regionParserService.getParsedKeyWords();
  }

  isEmptyRoleWithViewMode() {
    return this.data?.componentMode === ComponentViewMode.VIEW && this.data?.initInfo?.error;
  }

  get Feature() {
    return Feature;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
