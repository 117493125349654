<div class="card-list-container">
  <div class="view-container" fxLayout="row" fxLayoutAlign="space-between center">
    <!-- Search/Filter Icon -->
    <span fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="searchList.length">
        <mat-icon class="icon-button">search</mat-icon>
      </div>
      <div class="divider-container" fxLayout="row" fxLayoutAlign="center center">
        <mat-divider [vertical]="true"></mat-divider>
      </div>
      <div *ngIf="filterList.length">
        <mat-icon class="icon-button">filter_list</mat-icon>
      </div>
    </span>

    <!-- Buttons -->
    <ng-container *ngFor="let buttonItem of buttonList; let i = index">
      <button *ngIf="buttonItem?.buttonName" class="default-button" mat-flat-button [disabled]="buttonItem.isDisabledButton || externallyManaged"
        (click)="onNavigate(buttonItem)">
        {{buttonItem.buttonName | titlecase}}
      </button>
    </ng-container>
  </div>


  <!-- Card List -->
  <div class="card-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem">
    <ng-container *ngFor="let card of cardList; let cardIndex = index">
      <app-card class="card" [data]="card" [category]="category"></app-card>
    </ng-container>
  </div>
</div>

<mat-progress-spinner *ngIf="!hasLoadSuccess" class="spinner" [mode]="'indeterminate'" diameter="120">
</mat-progress-spinner>
