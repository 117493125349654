<div class="profile-container" fxLayout="column" fxLayoutAlign=" stretch" *ngIf="isDisplaySetting" [ngClass]="{'disable-toggle': disabledToggle}">
  <div class="mat-body-1 content-slide" fxLayout="row" fxLayoutAlign="start center">
    <zui-three-states-toggle class="slide-toggle" *ngIf="isUsingTriStatesMode()"
      [options]="triStateOptions"
      [toggleValue]="isToggleActive"
      [isDisabled]="disabledToggle"
      (toggleValueChanges)="setTriStateToggle($event)">
    </zui-three-states-toggle>
    <mat-slide-toggle class="slide-toggle" *ngIf="!isUsingTriStatesMode()"
      [disabled]="disabledToggle"
      [checked]="isToggleActive"
      (change)="setToggleActive($event)">
    </mat-slide-toggle>
    <span fxLayout="column" fxLayoutAlign="center stretch" class="mat-body">
      {{ label ? label : translations.companyView.editDivision.profileActive | translate }}
    </span>
    <mat-icon *ngIf="showIcon" class="tooltip-size" fxFlex="column wrap inline" fxLayoutAlign="center"
      matTooltip={{buildTooltipString()}} matTooltipPosition="below"
      [matTooltipClass]="hasTooltipTitle ? 'profile-tooltip allow-line-break' : 'profile-tooltip'">
      info_outline
    </mat-icon>
  </div>
</div>
