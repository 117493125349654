import { AddressType, CompanyStatus } from '@app/core/consts/company.const';
import { DivisionStatus, LocationStatus } from '@app/core/consts/division.const';
import { AuditInfo, ManagedEntity } from '@app/core/classes';

export interface IGetCompanyParams {
  legacyAccountCode?: string;
  name?: string;
  productId?: string;
  locationId?: string;
  q?: string;
  df?: string;
  sort?: string;
  page?: number;
  per_page?: number;
  status?: string;
  isParamsNameReset?: boolean;
  isParamsStatusReset?: boolean;
}

export interface IGetCompanyDivisionsParams {
  name?: string;
  type?: string;
  legacyAccountCode?: string;
  divisionIds?: string[];
  q?: string;
  df?: string;
  default_operator?: string;
  sort?: string;
  page?: number;
  per_page?: number;
  status?: CompanyStatus;
  allChildren?: boolean;
  // company_id: string; required parameter but part of URL only
}

export interface IGetCompanyLocationsParams {
  name?: string;
  status?: LocationStatus;
  page?: number;
  per_page?: number;
  // company_id: string; required parameter but part of URL only
}

export interface ICreateCompanyParams {
  name: string;
  shortName: string;
  anonymizedData: boolean;
  defaultLanguage: string;
}

export interface ICreateCompanyResult extends ICreateCompanyParams {
  id: string;
  created: string;
  modified: string;
  defaultDivisionId?: string;
}

export interface ICompany {
  name?: string;
  shortName?: string | null;
  id?: string;
  defaultDivisionId?: string;
  defaultLanguage?: string;
  status?: CompanyStatus;
  created?: string;
  modified?: string;
  anonymizedData?: boolean;
  managedEntity?: ManagedEntity;
};

export interface ICompanyDataSearchParams {
  page?: number;
  per_page?: number;
  q?: string;
  sort?: string;
  status?: CompanyStatus;
}

export interface ICompanyAddress {
  id?: string;
  type?: AddressType;
  country?: string;
  street?: string;
  street2?: string;
  zip?: string;
  city?: string;
  state?: string;
  county?: string | null;
};

export interface ICompanyInfo extends ICompanyAddress, ICompany {
  companyId: string;
}

export interface IDivision {
  id?: string;
  status?: string;
  name?: string;
  type?: string;
  locationId?: string;
  companyId?: string;
  parentId?: string;
  children?: string[];
  legacyAccountCode?: string;
  assetCount?: number;
  selected?: boolean;
  expanded?: boolean;
  hasHighlight?: boolean;
  managedEntity?: ManagedEntity;
  created?: string;
  modified?: string;
  auditInfo?: AuditInfo;
}


export interface ICreateDivisionParams {
  status: DivisionStatus;
  name: string;
  type: string;
  locationId?: string;
  parentId?: string;
  legacyAccountCode?: string;
}

export interface ICreateDivisionResult extends ICreateDivisionParams {
  id: string;
  companyId?: string;
  children?: string[];
}

export interface IGetDivisionParams {
  legacyAccountCode?: string;
  divisionIds?: string[];
}

export interface ILocation {
  id: string;
  name: string;
  address: Partial<ICompanyAddress>;
  timezone?: string;
  position: {
    lat: number;
    lon: number;
  };
  status: LocationStatus;
}
export interface IGetDotNumberParams {
  divisionId?: string;
  dotNumber?: string;
  q?: string;
  df?: string;
  default_operator?: string;
  sort?: string;
  page?: number;
  per_page?: number;
  // company_id: string; required as part of URL only.
}

export interface IDotNumber {
  id: string;
  dotNumber: string;
  companyId: string;
  divisionId: string;
}

export interface IApplication {
  created: string
  modified: string
  name: string
  type: string
  description: string
  identityProviderId: string
  allowManagedUsers: boolean
  id: string
  products: string[]
  permissions: string[]
  roles: IRole[]
  auditInfo: AuditInfo
}

export interface IRole {
  created: string
  modified: string
  description?: string
  restricted: boolean
  allowedTenants: string[]
  translationId: any
  type: string
  productId: string
  allowManagedUsers: boolean
  permissions: string[]
  apiPermissions: any[]
  id: string
  name: string
  auditInfo: AuditInfo
}

export interface IProduct {
  id: string,
  name: string,
  created?: string,
  modified?: string,
  type?: string,
  sku?: string,
  description?: string,
  auditInfo?: any,
  checked?: boolean
}
export interface ICompanyProduct {
  id: string;
  added: string;
  removed?: any;
  auditInfo: AuditInfo;
}
