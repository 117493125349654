import { Permissions } from '@app/core/consts/permission.const';

export const permissions = {
  defaultRedirectUrl: '/no-permission-route',
  defaultPermPrefix: 'self_service',
  routes: [
    ['company/', Permissions.READ_COMPANIES],
    ['company/:id/', Permissions.READ_COMPANY, { id: 'companyId' }],
    ['company/create-company', Permissions.CREATE_COMPANY],
    ['company/:id/edit', Permissions.READ_COMPANY, { id: 'companyId' }],

    // tabs
    ['company/:id/:tabName', Permissions.READ_USERS, { id: 'companyId' }],

    // division
    ['company/:id/division/create-division', Permissions.UPDATE_COMPANY, { id: 'companyId' }],
    ['company/:id/division/:divisionId/', Permissions.READ_COMPANY, { id: 'companyId' }],
    ['company/:id/division/:divisionId/:tabName', Permissions.READ_COMPANY, { id: 'companyId' }],
    ['company/:id/division/:divisionId/edit', Permissions.UPDATE_COMPANY, { id: 'companyId' }],

    // asset
    ['company/:id/create-asset', Permissions.CREATE_ASSET, { id: 'companyId' }],
    ['company/:id/custom-properties', Permissions.CREATE_ASSET, { id: 'companyId' }],
    ['company/:id/assets/:assetId/edit', Permissions.UPDATE_ASSET, { id: 'companyId' }],
    ['company/:id/assets/edit-assets', Permissions.UPDATE_ASSET, { id: 'companyId' }],
    ['company/:id/assets/', Permissions.READ_ASSETS, { id: 'companyId' }],

    // user
    ['company/:id/user/:userId/edit', Permissions.UPDATE_USER, { id: 'companyId' }],
    ['company/:id/user/edit-users', Permissions.UPDATE_USER, { id: 'companyId' }],
    ['company/:id/create-user', Permissions.CREATE_USER, { id: 'companyId' }],

    // policy
    ['company/:id/role/:policyId/edit', Permissions.UPDATE_POLICY, { id: 'companyId' }],
    ['company/:id/role/:policyId/view', Permissions.READ_POLICY, { id: 'companyId' }],
    ['company/:id/create-role', Permissions.CREATE_USER, { id: 'companyId' }],
  ],
};

export const appName = 'self-service';
