import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingDataStore } from './setting-data-store.interface';
import { featureKey } from './setting-data-store.reducer';

export const selectSettingState = createFeatureSelector<SettingDataStore>(featureKey);

export const selectHasAllowManaged = createSelector(selectSettingState, state => {
  return state?.hasAllowManaged;
});

export const selectGroupPolicySetting = createSelector(selectSettingState, state => {
  return state?.setting;
});
