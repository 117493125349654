import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CautionPopupComponent } from '@app/shared/dialogs/caution-popup/caution-popup.component';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { PreviewDialogComponent } from '@app/shared/dialogs/preview-dialog/preview-dialog.component';
import { AnnouncementDialogComponent } from '@app/shared/dialogs/announcement-dialog/announcement-dialog.component';
import { GeneratePasswordDialogComponent } from '@app/shared/dialogs/generate-password-dialog/generate-password-dialog.component';
import { AlertDialogComponent } from '@app/shared/dialogs/alert-dialog/alert-dialog.component';
import { BulkAssetImportComponent } from '@app/shared/dialogs/bulk-asset-import/bulk-asset-import.component';
import { BulkEditResultComponent } from '@app/shared/dialogs/bulk-edit-result/bulk-edit-result.component';
import { BulkUserImportComponent } from '@app/shared/dialogs/bulk-user-import/bulk-user-import.component';
import { BulkUserPreviewDialogComponent } from '@app/shared/dialogs/bulk-user-preview-dialog/bulk-user-preview-dialog.component';
import { of } from 'rxjs';
import { CreateEditWithTreeDialogComponent } from '@app/shared/dialogs/create-edit-with-tree-dialog/create-edit-with-tree-dialog.component';
import { MigrationStartDialogComponent } from '@app/shared/dialogs/migration-start-dialog/migration-start-dialog.component';
import { IIndeterminateDialogParams } from '@app/core/consts/group.const';
import { MigrationIndeterminateDialogComponent } from '@app/shared/dialogs/migration-indeterminate-dialog/migration-indeterminate-dialog.component';
import { MigrationDialogIds } from '@app/core/consts/app.const';
import { CreateEditCustomPropertyDialogParams } from '../models/asset-property.model';
import { AssetPropertyDialogIds } from '../consts/asset-property.const';
import { CreateEditCustomPropertyDialogComponent } from '@app/shared/dialogs/create-edit-custom-property-dialog/create-edit-custom-property-dialog.component';
import { InformationDialogParams } from '../models/asset.model';
import { ConfirmMessageDialogComponent } from '@app/shared/dialogs/confirm-message-dialog/confirm-message-dialog.component';
import { InformationDialogComponent } from '@app/shared/dialogs/information-dialog/information-dialog.component';
import { ConfirmChangeDialogComponent } from '@app/shared/dialogs/confirm-change-dialog/confirm-change-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    private dialog: MatDialog,
  ) { }

  showCautionPopup(params: any, width: string, height: string) {
    return this.dialog.open(CautionPopupComponent, {
      data: {
        title: params.title,
        message1: params.message1,
        message2: params.message2,
        textCancelButton: params.textCancelButton,
        textConfirmButton: params.textConfirmButton,
        errorMessage: params.errorMessage
      },
      height,
      width,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  // TODO: This is an old design for confirm dialogs, please check if want to keep/use new confirm-message-dialog
  showConfirmDialog(params: any, width: string, height: string) {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: params.title,
        messages: params.messages,
        textCancelButton: params.textCancelButton,
        textConfirmButton: params.textConfirmButton,
      },
      width,
      height,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showConfirmMessageDialog(params: any, width: string, height: string) {
    return this.dialog.open(ConfirmMessageDialogComponent, {
      data: {
        title: params.title,
        messages: params.messages,
        textCancelButton: params.textCancelButton,
        textConfirmButton: params.textConfirmButton,
      },
      width,
      height,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showPreviewDialog(params: any, width: string, maxHeight: string) {
    return this.dialog.open(PreviewDialogComponent, {
      data: {
        title: params?.title,
        description: params?.description,
        textCancelButton: params?.textCancelButton,
        textProceedButton: params?.textProceedButton,
        valueChanged: params?.valueChanged,
        showMore: params?.showMore,
        showLess: params?.showLess,
        removed: params?.removed,
        active: params?.active,
        inactive: params?.inactive,
        on: params?.on,
        off: params?.off
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showBulkUserPreviewDialog(params: any, width: string, maxHeight: string) {
    return this.dialog.open(BulkUserPreviewDialogComponent, {
      data: params,
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showAnnouncementDialog(params: any, width: string, maxHeight: string) {
    return this.dialog.open(AnnouncementDialogComponent, {
      data: {
        title: params?.title,
        titleDescription: params?.titleDescription,
        description: params?.description,
        textCancelButton: params?.textCancelButton,
        textContinueButton: params?.textContinueButton,
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showGeneratePasswordDialog(params: any, width: string, maxHeight: string) {
    return this.dialog.open(GeneratePasswordDialogComponent, {
      data: {
        title: params?.title,
        bodyText: params?.bodyText,
        titleDescription: params?.titleDescription,
        description: params?.description,
        textCloseButton: params?.textCloseButton
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showAlertDialogComponent(params: any, width: string, maxHeight: string) {
    return this.dialog.open(AlertDialogComponent, {
      data: {
        headIcon: params?.headIcon || '',
        title: params?.title || '',
        messages: params?.messages || '',
        textConfirmButton: params?.textConfirmButton || '',
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showBulkImportDialog(params, width: string, maxHeight: string) {
    return this.dialog.open(BulkAssetImportComponent, {
      data: {
        companyId: params.companyId,
        fieldsCheckDup: params.fieldsCheckDup,
        pageName: params.pageName,
        translatedText: params.translatedText
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showBulkUserImportDialog(params, width: string, maxHeight: string) {
    return this.dialog.open(BulkUserImportComponent, {
      data: {
        companyId: params.companyId,
        companyDefaultLanguage: params.companyDefaultLanguage,
        translatedText: params.translatedText,
        hasManagedUser: params.hasManagedUser
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showBulkEditDialog(params, width: string, maxHeight: string) {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
      return of(false);
    }

    return this.dialog.open(BulkEditResultComponent, {
      data: {
        title: params.title,
        message1: params.message1,
        message2: params.message2,
        bulkEditResults: params.bulkEditResults,
        textCloseButton: params.textCloseButton
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showCreateEditWithTree(params, width: string, maxHeight: string) {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) {
      return of(false);
    }

    return this.dialog.open(CreateEditWithTreeDialogComponent, {
      data: {
        dialogText: params.dialogText,
        initInfo: params.initInfo,
        mode: params.mode,
        dataList: params.dataList,
        dataValidation: params.dataValidation,
        enableDeterminate: params.enableDeterminate,
        feature: params.feature,
        isDialog: params.isDialog,
        allItemsChecked: params.allItemsChecked,
        disableSubItemSelection: params.disableSubItemSelection,
        companyLevelCapaSelectedList: params?.companyLevelCapaSelectedList,
        allRoleCompanyLevelCapaList: params?.allRoleCompanyLevelCapaList
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showMigrationStartDialog(params: any, width: string, maxHeight: string) {
    if (this.dialog.getDialogById(MigrationDialogIds.MIGRATION_DIALOG)) {
      return of(false);
    }

    return this.dialog.open(MigrationStartDialogComponent, {
      data: {
        title: params.title,
        message1: params.message1,
        message2: params.message2,
        message3: params.message3,
        message4: params.message4,
        button: params.button
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true,
      id: MigrationDialogIds.MIGRATION_DIALOG,
    }).afterClosed();
  }

  showIndeterminateDialog(params: IIndeterminateDialogParams, width: string, maxHeight: string) {
    if (this.dialog.getDialogById(MigrationDialogIds.INDETERMINATE_DIALOG)) {
      return of(false);
    }

    return this.dialog.open(MigrationIndeterminateDialogComponent, {
      id: MigrationDialogIds.INDETERMINATE_DIALOG,
      data: {
        mode: params.mode,
        apiFetchIntervalTime: params.apiFetchIntervalTime,
        progressBarIcon: params.progressBarIcon,
        companyId: params.companyId
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true
    }).afterClosed();
  }

  showCreateEditCustomPropertyDialog(params: CreateEditCustomPropertyDialogParams, width: string) {
    if (this.dialog.getDialogById(AssetPropertyDialogIds.CREATE_EDIT_CUSTOM_PROPERTY_DIALOG)) {
      return of(false);
    }

    return this.dialog.open(CreateEditCustomPropertyDialogComponent, {
      id: AssetPropertyDialogIds.CREATE_EDIT_CUSTOM_PROPERTY_DIALOG,
      data: {
        ...params
      },
      width,
      disableClose: true,
      closeOnNavigation: true,
    }).afterClosed();
  }

  showInformationDialog(params: InformationDialogParams, width: string, maxHeight: string) {
    return this.dialog.open(InformationDialogComponent, {
      data: {
        ...params
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true,
    }).afterClosed();
  }

  showConfirmChangeCompanyDialog(params: any, width: string, maxHeight: string) {
    return this.dialog.open(ConfirmChangeDialogComponent, {
      data: {
        ...params,
      },
      width,
      maxHeight,
      disableClose: true,
      closeOnNavigation: true,
    }).afterClosed();
  }
}
