import { ListManagementType } from "@app/core/consts/list-management.const";
import { PolicyListTableColDef } from "@app/core/consts/policy.const";
import { ListManagement } from "@app/core/models/list-management";
import { Translations } from "@app/core/services/i18n/translations.service";
import { ZonarUITableCellType as cellType, ZonarUITableCellType } from '@zonar-ui/table';
import { FeatureToggleService } from "@app/shared/services/feature-toggle.service";
import { FeatureKeys } from "@app/core/consts/app.const";

export function getPolicyListCompanyColumnConfig(featureToggleService: FeatureToggleService) {
    return {
    [PolicyListTableColDef.CHECK_BOX]: { 'max-width': '5%' },
    [PolicyListTableColDef.POLICY_NAME]: featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.EDIT) ?
      { 'max-width': '40%' } :
      { 'max-width': '65%' },
    [PolicyListTableColDef.USER_COUNT]: featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.EDIT) ?
      { 'max-width': '10%', 'justify-content': 'right' } :
      { 'max-width': '30%', 'justify-content': 'right', 'margin-right': '1.313rem' },
    [PolicyListTableColDef.EDIT]: { 'max-width': '45%', 'justify-content': 'right', 'margin-right': '1.313rem' },
  };
}

export function getPolicyListColumns(translations: Translations, translated, featureToggleService: FeatureToggleService, companyId: string, isZonarUser: boolean, hasUpdatePoliciesPerm: boolean) {
  return [
    {
      columnDef: PolicyListTableColDef.POLICY_NAME,
      header: translated[translations.companyView.roleList.tableHeaderName].toUpperCase(),
      type: ZonarUITableCellType.Portal,
      sortable: true,
    },
    {
      columnDef: PolicyListTableColDef.USER_COUNT,
      header: translated[translations.companyView.roleList.tableHeaderUserCount].toUpperCase(),
      sortable: false,
      cell: (row: any) => row.userCount ?? '-',
    },
    {
      columnDef: PolicyListTableColDef.EDIT,
      header: '',
      type: cellType.Link,
      iLink: (row) => `/company/${companyId}/role/${row.id}/edit`,
      sortable: false,
      cell: (row) => row.grants.find(grant => grant.roles.find(role => role.restricted)) ? null : translated[translations.companyView.roleList.tableCellEditLink],
      hidden: !featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.EDIT) || !hasUpdatePoliciesPerm
    },
  ];
}

export function getPolicyListSearchActionView(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Search,
    searchManagement: {
      controlName: 'name',
      searchQuery: [PolicyListTableColDef.POLICY_NAME],
      searchPlaceHolder: translated[translations.companyView.roleList.searchPlaceholder],
    }
  };
}

export function getPolicyListCreatePolicyButtonManagement(menuList, translations: Translations, translated, featureToggleService: FeatureToggleService, companyId: string) {
  return {
    locateNavigate: `/company/${companyId}/create-role`,
    buttonName: featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.CREATE) ? translated[translations.companyView.roleList.newRoleButton] : null,
    menuName: '',
    hasMenu: menuList.length ? true : false,
    menuList: menuList
  };
}

export const PolicyListCreatePolicyButton: ListManagement = {
  type: ListManagementType.Button,
  buttonManagement: {
    isDisabledButton: false,
    isOpenDialog: false
  },
};

export function getDisplayPolicyData(data: any, translations: Translations, translated) {
  return {
    title: data.name,
    userText: translated ? translated[translations.companyView.roleList.tableHeaderUserCount] : '',
    userCount: data.userCount,
    viewText: translated ? translated[translations.companyView.roleList.tableCellView] : '',
    viewLink: `//company/${data?.companyId}/role/${data?.id}/view`,
    editText: translated ? translated[translations.companyView.roleList.tableCellEditLink] : '',
    editLink: `//company/${data?.companyId}/role/${data?.id}/edit`,
  };
}
