import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DialogService } from '@app/core/services/dialog.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { RegionParserService } from '@app/core/services/region-parser.service';

@Component({
  selector: 'app-setting-toggle',
  templateUrl: './setting-toggle.component.html',
  styleUrls: ['./setting-toggle.component.scss']
})
export class SettingToggleComponent implements OnChanges, OnDestroy {
  @Input() isToggleActive = false;
  @Input() isDisplaySetting: boolean;
  @Input() cautionText: any;
  @Input() showCautionConfirm = true;
  @Input() showNotificationOff = false;
  @Input() showNotificationOn = false;
  @Input() hasShowMessageProfileOn = false;
  @Input() disabledToggle = false;
  @Input() label = '';
  @Input() hasTooltipTitle = true;
  @Input() showIcon = true;
  @Input() toggleName: string;
  @Input() triStateOptions = [];


  @Output() toggleEvent = new EventEmitter<{}>();

  translationsNotLoaded = !!this.translateService.store.translations;
  private durations = 7;
  private onDestroy$ = new Subject<void>();
  constructor(
    private i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    private dialogService: DialogService,
    private zonarUiNotificationsService: ZonarUiNotificationsService,
    public parsingService: RegionParserService
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showNotificationOn && changes.showNotificationOn.currentValue) {
      this.zonarUiNotificationsService.openSuccess(
        '',
        this.cautionText.messagesNotificationOn,
        this.durations
      );
    } else if (changes.hasShowMessageProfileOn && changes.hasShowMessageProfileOn.currentValue) {
      this.zonarUiNotificationsService.openSuccess(
        '',
        this.cautionText.messageProfileOn,
        this.durations
      );
    }
  }

  setToggleActive(event) {
    if (this.isToggleActive && this.showCautionConfirm) {
      event.source.checked = true;
      this.dialogService.showCautionPopup(this.cautionText, '25vw', '45vh').subscribe(res => {
        if (res) {
          this.isToggleActive = !this.isToggleActive;
          this.toggleEvent.emit({
            value: this.isToggleActive,
            toggleName: this.toggleName
          });
        } else {
          this.isToggleActive = true;
        }
      });
    } else {
      if (this.showNotificationOff) {
        event.source.checked = false;
        this.zonarUiNotificationsService.openError(
          '',
          this.cautionText.messagesNotificationOff,
          this.durations
        );
      } else {
        this.isToggleActive = !this.isToggleActive;
        this.toggleEvent.emit({
          value: this.isToggleActive,
          toggleName: this.toggleName
        });
      }
    }
  }

  // Emit the value and toggle name to parent component.
  setTriStateToggle(event) {
    this.toggleEvent.emit({
      value: event,
      toggleName: this.toggleName
    });
  }

  buildTooltipString() {
    const result = (this.cautionText?.title || '') + (this.cautionText?.tooltipContext || '');
    return result ;
  }

  getParsedKeyWords() {
    return this.parsingService.getParsedKeyWords();
  }

  isUsingTriStatesMode() {
    return !!this.triStateOptions?.length;
  }

  ngOnDestroy(): void {
    this.zonarUiNotificationsService.dismiss();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
